import React from 'react'
import {Navigate} from 'react-router-dom'
import { useAppSelector } from '../../hooks/redux'
import LoginForm from '../../components/forms/LoginForm'

const Auth: React.FC = () => {
    const isLoggedIn = useAppSelector(state => state.userReducer.isLoggedIn)
	
    if (isLoggedIn) {
        return (
            <Navigate to={"/dashboard"} replace/>
        )
    }

    return (
        <div className="flex flex-col items-center px-6 py-12 mx-auto md:h-screen">
            <div className="min-w-[350px] md:min-w-[500px]">
                <div className='flex items-center justify-center w-full py-8'>
                    <a
                        href="#"
                        className="flex items-center text-3xl mr-3 border-b-2 boder-aqua font-semibold text-aqua"
                    >
                        FINPEAK
                    </a>
                </div>
                <div className="bg-white rounded-[30px] shadow p-6 space-y-8 md:space-y-10 sm:p-8">
                    <LoginForm/>
                </div>
            </div>
        </div>
    )
}

export default Auth