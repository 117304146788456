import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';

interface GroupedOrder {
  address: string;
  totalPrice: number;
  deliveryPrice: number;
  commission: number;
  retention: number;
  tip: number;
  totalPaidPrice: number
  totalOrders: number;
  successfulOrders: number;
  unsuccessfulOrders: number;
}

interface Props {
  groupedOrders: GroupedOrder[];
}

const getColumnTotal = (data: any, key: any) => {
    return data.reduce((sum: any, record: any) => sum + (record[key] || 0), 0);
};

const MainTable: React.FC<Props> = ({ groupedOrders }) => {
  const numberFormatter = new Intl.NumberFormat('ru-RU', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  const [data, setData] = useState<GroupedOrder[]>([]);

  const calculateTotals = (data: any) => {
    const totals = {
      address: "Итоги",
      totalPaidPrice: 0,
      totalPrice: 0,
      deliveryPrice: 0,
      commission: 0,
      retention: 0,
      tip: 0,
      totalOrders: 0,
      successfulOrders: 0,
      unsuccessfulOrders: 0,
    };

    data.forEach((record: any) => {
      totals.totalPaidPrice += Number(record.totalPaidPrice) || 0;
      totals.totalPrice += Number(record.totalPrice) || 0;
      totals.deliveryPrice += Number(record.deliveryPrice) || 0;
      totals.commission += Number(record.commission) || 0;
      totals.retention += Number(record.retention) || 0;
      totals.tip += Number(record.tip) || 0;
      totals.totalOrders += Number(record.totalOrders) || 0;
      totals.successfulOrders += Number(record.successfulOrders) || 0;
      totals.unsuccessfulOrders += Number(record.unsuccessfulOrders) || 0;
    });

    return totals;
  };

  useEffect(() => {
    const totals = calculateTotals(groupedOrders);
    const dataWithSummary = [...groupedOrders, totals];
    
    setData(dataWithSummary)
  }, [groupedOrders])

  const columns: ColumnsType<GroupedOrder> = [
    {
      title: 'Адрес',
      dataIndex: 'address',
      key: 'address',
      filters: Array.from(new Set(groupedOrders.map(order => ({ text: order.address, value: order.address })))),
      onFilter: (value, record) => record.address === value,
    },
    {
      title: 'Общая Сумма (Завершенные заказы)',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      render: (value) => numberFormatter.format(value),
      sorter: (a, b) => a.totalPrice - b.totalPrice,
    },
    {
        title: 'Компенсация (Отмененные заказы)',
        key: 'totalCompensatePrice',
        render: (record: GroupedOrder) => numberFormatter.format(record.totalPaidPrice-record.totalPrice),
    },
    {
      title: 'Стоимость доставки',
      dataIndex: 'deliveryPrice',
      key: 'deliveryPrice',
      render: (value) => numberFormatter.format(value),
      sorter: (a, b) => a.deliveryPrice - b.deliveryPrice,
    },
    {
      title: 'Комиссия',
      dataIndex: 'commission',
      key: 'commission',
      render: (value) => numberFormatter.format(value),
      sorter: (a, b) => a.commission - b.commission,
    },
    {
      title: 'Удержание',
      dataIndex: 'retention',
      key: 'retention',
      render: (value) => numberFormatter.format(value),
      sorter: (a, b) => a.retention - b.retention,
    },
    {
      title: 'Чаевые',
      dataIndex: 'tip',
      key: 'tip',
      render: (value) => numberFormatter.format(value),
      sorter: (a, b) => a.tip - b.tip,
    },
    {
      title: 'Всего заказов',
      dataIndex: 'totalOrders',
      key: 'totalOrders',
      render: (value) => numberFormatter.format(value),
      sorter: (a, b) => a.totalOrders - b.totalOrders,
    },
    {
      title: 'Успешные заказы',
      dataIndex: 'successfulOrders',
      key: 'successfulOrders',
      render: (value) => numberFormatter.format(value),
      sorter: (a, b) => a.successfulOrders - b.successfulOrders,
    },
    {
      title: 'Неуспешные заказы',
      dataIndex: 'unsuccessfulOrders',
      key: 'unsuccessfulOrders',
      render: (value) => numberFormatter.format(value),
      sorter: (a, b) => a.unsuccessfulOrders - b.unsuccessfulOrders,
    },
  ];

  return <Table columns={columns} dataSource={data} rowKey="address"/>;
};

export default MainTable;