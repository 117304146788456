import { API_URL } from "./consts";

export const convertDate = (isoDateString: string) => {
    const date = new Date(isoDateString);

    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
    };

    const russianDate = date.toLocaleString('ru-RU', options);
    return russianDate
}

export const validatePassword = (value: string) => {
    // Password strength criteria (you can adjust these as needed)
    const minLength = 6;
    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumber = /\d/.test(value);

    if (value.length < minLength || !hasUpperCase || !hasLowerCase || !hasNumber) {
      return 'Пароль должен иметь длину не менее 6 символов и содержать хотя бы одну заглавную букву, одну строчную букву и одну цифру.';
    }

    return true; // Return true if password is strong
};

export const validateBankAccount = (value: string) => {
    const pattern = /^[A-Za-z]+ - \d{4}$/;
    
    // Test if the input matches the pattern
    return pattern.test(value);
}

export const getWallets = async (url: string) => {
    try {
        const response = await fetch(`${API_URL}/sheets/wallets`, {
            method: 'POST',
            body: JSON.stringify({googleSheetsUrl: url})
        })
        const res = response.json()
        return res
    } catch(e) {
        console.log(e)
    }
}

export function sortItemsByMenuOrder(items: any[] | undefined, menuItems: string[]) {
    if (!items) {
        return [];
    }

    const itemCopy = [...items]
    
    return itemCopy.sort((a, b) => {
        return menuItems.indexOf(a.name) - menuItems.indexOf(b.name);
    });
}