import React, { FC } from 'react';
import { Table } from 'antd';
import { CancelledOrders } from './types';
import { ColumnType } from 'antd/es/table';

interface CancelledOrdersTableProps {
    cancelledOrders: CancelledOrders[];
}

const CancelledOrdersTable: FC<CancelledOrdersTableProps> = ({ cancelledOrders }) => {
    const addressFilters = Array.from(new Set(cancelledOrders.map(order => order.address))).map(address => ({
        text: address, // Display text for the filter
        value: address, // Value to filter by
    }));

    const cancelReasonFilters = Array.from(new Set(cancelledOrders.map(order => order.cancelReason))).map(reason => ({
        text: reason,
        value: reason,
    }));

    const columns: ColumnType<CancelledOrders>[] = [
        {
            title: 'Причина отмены',
            dataIndex: 'cancelReason',
            key: 'cancelReason',
            filters: cancelReasonFilters,
            onFilter: (value: any, record: CancelledOrders) => record.cancelReason === value,
        },
        {
            title: 'Адрес',
            dataIndex: 'address',
            key: 'address',
            filters: addressFilters,
            onFilter: (value: any, record: CancelledOrders) => record.address === value,
        },
        {
            title: 'Сумма заказов',
            dataIndex: 'totalCost',
            key: 'totalCost',
            render: (value: number) => value.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        },
        {
            title: 'Количество заказов',
            dataIndex: 'orderCount',
            key: 'orderCount',
        },
    ];

    return <Table columns={columns} dataSource={cancelledOrders} rowKey="id" pagination={false} />;
};

export default CancelledOrdersTable;