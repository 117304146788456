import React, { FC, useMemo } from 'react';
import { Table } from 'antd';
import { IssueOrders } from './types';
import { ColumnType } from 'antd/es/table';

interface IssueOrdersTableProps {
    issueOrders: IssueOrders[];
}

const extractAndReplaceIssues = (input: string): string => {
    const issues = input.match(/\[([^\]]+)\]/g);
    return issues ? issues.map(issue => issue.slice(1, -1)).join(', ') : input;
};

const IssueOrdersTable: FC<IssueOrdersTableProps> = ({ issueOrders }) => {
    const addressFilters = Array.from(new Set(issueOrders.map(order => order.address))).map(address => ({
        text: address, // Display text for the filter
        value: address, // Value to filter by
    }));

    const issueFilters = useMemo(() => {
        const issuesSet = new Set<string>();
        issueOrders.forEach(item => {
            const issues = extractAndReplaceIssues(item.issue).split(', ');
            issues.forEach(issue => issuesSet.add(issue));
        });
        return Array.from(issuesSet).map(issue => ({ text: issue, value: issue }));
    }, []);

    const columns: ColumnType<IssueOrders>[] = [
        {
            title: 'Адрес',
            dataIndex: 'address',
            key: 'address',
            filters: addressFilters,
            onFilter: (value: any, record: IssueOrders) => record.address === value,
        },
        {
            title: 'Проблема',
            dataIndex: 'issue',
            key: 'issue',
            render: (text: string) => extractAndReplaceIssues(text), // Replace original values with extracted issues
            filters: issueFilters, // Filters for the issues
            onFilter: (value: any, record: IssueOrders) => extractAndReplaceIssues(record.issue).includes(value),
        },
        {
            title: 'Сумма возврата',
            dataIndex: 'refundAmount',
            key: 'refundAmount',
            render: (value: number) => value.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        },
        {
            title: 'Количество заказов',
            dataIndex: 'orderCount',
            key: 'orderCount',
        },
    ];

    return <Table columns={columns} dataSource={issueOrders} rowKey="id" pagination={false} />;
};

export default IssueOrdersTable;