import { Session } from '../models/shashlychok/Session';
import { User } from '../models/shashlychok/User'
import { baseApi } from './baseApi'


export const shashlychokApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getUsers: builder.query<User[], string>({
            query: () => "/shashlychok/users"
        }),
        getSessionByDate: builder.mutation<{session: Session, prevSession: Session}, {userId: number, date: string}>({
            query: (params) => {
                // Construct query string from params
                const queryParams = new URLSearchParams(params as any).toString();
                return `/shashlychok/session?${queryParams}`;
            },
        }),
        setSpend: builder.mutation<any, any>({
            query: (body) => ({
                url: "/shashlychok/spend",
                method: 'POST',
                body: body
            })
        }),
        setExtra: builder.mutation<any, any>({
            query: (body) => ({
                url: "/shashlychok/extra",
                method: 'POST',
                body: body
            })
        }),
        setTable: builder.mutation<any, any>({
            query: (body) => ({
                url: '/shashlychok/items',
                method: 'POST',
                body: body
            })
        })
    })
})


export const {useGetUsersQuery, useGetSessionByDateMutation, useSetSpendMutation, useSetExtraMutation, useSetTableMutation} = shashlychokApi