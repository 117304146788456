import { FC, PropsWithChildren, memo } from 'react';
import classNames from 'classnames';

export interface TitleProps {
    small?: boolean;
    medium?: boolean;
    large?: boolean;
    xl?: boolean;
    className?: string;
}

export const Title: FC<PropsWithChildren<TitleProps>> = ({ className, small, medium, large, xl, children, ...props }) => {
    const classes = classNames(`${className} font-bold text-gray-800`, {
        'text-lg md:text-xl': small,
        'text-xl md:text-2xl': medium,
        'text-2xl md:text-3xl': large,
        'text-3xl md:text-4xl': xl
    });

    return (
        <h1
            className={classes}
            {...props}>
            {children}
        </h1>
    );
};
