import { FC, useEffect, useState } from 'react'
import Sidebar from '../Sidebar'
import { Outlet, useLocation } from 'react-router-dom'
import { TbUserPlus } from "react-icons/tb";
import SidebarLink from '../../SidebarLink';
import { FiUsers } from 'react-icons/fi';


const EmployeeLayout: FC = () => {
    const location = useLocation()
    const [currentLink, setCurrentLink] = useState<string>("/employee")

    useEffect(() => {
        setCurrentLink(location?.pathname)
    }, [])

    return (
        <div className='p-4 md:p-12'>
            <div className='text-3xl text-white font-semibold mb-8 md:mb-12'>Управление сотрудниками</div>
            <div className='grid grid-cols-1 lg:grid-cols-5 gap-10'>
                <div className='lg:col-span-1'>
                    <Sidebar>
                        <SidebarLink
                            to='/employee'
                            text='Список сотрудников'
                            setCurrentLink={() => setCurrentLink("/employee")}
                            currentLink={currentLink}
                            icon={<FiUsers className='shrink-0 text-slate-200' size={27} />}
                        />
                        <SidebarLink
                            to='/employee/add'
                            text='Добавить сотрудника'
                            setCurrentLink={() => setCurrentLink("/employee/add")}
                            currentLink={currentLink}
                            icon={<TbUserPlus className='shrink-0 text-slate-200' size={27} />}
                        />
                    </Sidebar>
                </div>
                <div className='lg:col-span-4'>
                    <Outlet/>
                </div>
            </div>
        </div>
    )
}

export default EmployeeLayout