import React from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';


interface RetentionOrder {
    retentionReason: string;
    address: string;
    totalRetention: number;
    totalOrders: number;
    successfulOrders: number;
    unsuccessfulOrders: number;
  }
  
interface RetentionProps {
    retentionOrders: RetentionOrder[];
}

const RetentionOrdersTable: React.FC<RetentionProps> = ({ retentionOrders }) => {
    const numberFormatter = new Intl.NumberFormat('ru-RU', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    });

    const columns: ColumnsType<RetentionOrder> = [
        {
            title: 'Адрес',
            dataIndex: 'address',
            key: 'address',
            filters: Array.from(new Set(retentionOrders.map(order => order.address))).map(address => ({ text: address, value: address })),
            onFilter: (value, record) => record.address === value,
        },
        {
        title: 'Причина удержания',
        dataIndex: 'retentionReason',
        key: 'retentionReason',
        filters: Array.from(new Set(retentionOrders.map(order => order.retentionReason))).map(address => ({ text: address, value: address })),
        onFilter: (value, record) => record.retentionReason === value,
        },
        {
        title: 'Общая удержанная сумма',
        dataIndex: 'totalRetention',
        key: 'totalRetention',
        render: (value) => numberFormatter.format(value),
        sorter: (a, b) => a.totalRetention - b.totalRetention,
        },
        {
        title: 'Количество заказов',
        dataIndex: 'totalOrders',
        key: 'totalOrders',
        render: (value) => numberFormatter.format(value),
        sorter: (a, b) => a.totalOrders - b.totalOrders,
        },
        {
        title: 'Успешные заказы',
        dataIndex: 'successfulOrders',
        key: 'successfulOrders',
        render: (value) => numberFormatter.format(value),
        sorter: (a, b) => a.successfulOrders - b.successfulOrders,
        },
        {
        title: 'Неуспешные заказы',
        dataIndex: 'unsuccessfulOrders',
        key: 'unsuccessfulOrders',
        render: (value) => numberFormatter.format(value),
        sorter: (a, b) => a.unsuccessfulOrders - b.unsuccessfulOrders,
        },
    ];

    return <Table columns={columns} dataSource={retentionOrders} rowKey="id" />;
};

export default RetentionOrdersTable;