import { File } from '../models/File'
import { baseApi } from './baseApi'


export const filesApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getFiles: builder.query<File[], void>({
            query: () => ({
                url: "/files",
                method: "GET",
            }),
            providesTags: (_) => ["Files"]
        }),
        addFiles: builder.mutation<File, File>({
            query: (body) => ({
                url: "/files",
                method: "POST",
                body: body
            }),
            invalidatesTags: (_) => ["Files"]
        }),
        parseFiles: builder.mutation({
            query: (body) => ({
                url: "/files/parse",
                method: "POST",
                body: body
            }),
            invalidatesTags: (_) => ["Files"]
        }),
    })
})

export const { useAddFilesMutation, useGetFilesQuery, useParseFilesMutation } = filesApi