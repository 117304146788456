import React, { FC, useState } from 'react'
import { Title } from '../../UI/Title'
import { Form } from '../../UI/Form'
import { SubmitHandler, useForm } from 'react-hook-form'
import DatePicker, { registerLocale } from 'react-datepicker'
import { format } from 'date-fns';
import useToast from '../../../hooks/useToast'
import { useGetYandexByDateMutation } from '../../../api/chickenFoodApi'
import YandexInfo from './YandexInfo'
import { ru } from 'date-fns/locale/ru';
import { YandexTableProps } from '../../../models/chicken-food/YandexOrder'


interface FormData {
    fromDate: string
    toDate: string
}

const formatDate = (date: Date | null): string => {
    return date ? format(date, 'yyyy-MM-dd') : '';
};

const YandexForm: FC = () => {
    registerLocale('ru', ru)
    const [info, setInfo] = useState<YandexTableProps>()
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    const [getInfo, {isLoading}] = useGetYandexByDateMutation()
    const {register, setValue, handleSubmit} = useForm<FormData>()

    const [isShaking, setShaking] = useState<boolean>(false);
    const { showToast, ToastComponent } = useToast();

    const shake = () => {
        setShaking(true);

        setTimeout(() => {
            setShaking(false);
        }, 1000);
    };

    const handleStartDateChange = (date: Date | null) => {
        console.log(date)
        setStartDate(date)
        setValue("fromDate", formatDate(date))
    };

    const handleEndDateChange = (date: Date | null) => {
        setEndDate(date)
        setValue("toDate", formatDate(date))
    };

    const submit: SubmitHandler<FormData> = async (data: FormData) => {
        try {
            const response = await getInfo(data).unwrap()
            setInfo(response)
        } catch(error: any) {
            shake()
            showToast(error?.data?.message, {error: true})
        }
    }

    return (
        <div className='flex flex-col space-y-10'>
            <Title className='text-slate-300' large>Yandex Отчеты</Title>
            <Form className='flex flex-col md:flex-row md:items-center md:justify-between space-y-6 md:space-y-0 max-w-3xl' onSubmit={handleSubmit(submit)}> 
                <div className='flex flex-col space-y-2'>
                    <label className='text-gray-300 text-lg lg:text-xl'>Выберите начальную дату</label>
                    <DatePicker
                        locale={"ru"}
                        selected={startDate}
                        onChange={handleStartDateChange}
                        dateFormat="dd.MM.yyyy"
                        placeholderText="Выберите дату"
                        className='bg-white w-[100%] px-3 py-2 rounded'
                    />
                </div>
                <div className='flex flex-col space-y-2'>
                    <label className='text-gray-300 text-lg lg:text-xl'>Выберите конечную дату</label>
                    <DatePicker
                        locale={"ru"}
                        selected={endDate}
                        onChange={handleEndDateChange}
                        dateFormat="dd.MM.yyyy"
                        placeholderText="Выберите дату"
                        className='bg-white w-[100%] px-3 py-2 rounded'
                    />
                </div>
                <Form.FormButton
                    type='submit'
                    text='Применить'
                    theme='light'
                    isShaking={isShaking}
                    isLoading={isLoading}
                />
                {ToastComponent()}
            </Form>
            <YandexInfo
                groupedOrders={info?.groupedOrders}
                cancelledOrders={info?.cancelledOrders}
                retentionOrders={info?.retentionOrders}
            />
        </div>
    )
}

export default YandexForm