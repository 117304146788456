import { PowerBiResponse } from '../models/responses/PowerBiResponse'
import { baseApi } from './baseApi'

export const reportApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getReportCredentials: builder.query<PowerBiResponse, string>({
            query: () => ({
                url: '/power-bi',
                method: "GET",
            }),
        })
    })
})

export const {useGetReportCredentialsQuery} = reportApi