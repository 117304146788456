import React, { FC, useEffect, useState } from 'react';
import { Session } from '../../../models/shashlychok/Session';
import { Title } from '../../UI/Title';
import { parseISO, format } from 'date-fns';
import Spinner from '../../UI/Spinner';
import { extra_items, locations, menuItems, prices } from '../../../utils/consts';
import { sortItemsByMenuOrder } from '../../../utils/utils';
import { Item } from '../../../models/shashlychok/Item';
import { Form } from '../../UI/Form';
import { SubmitHandler, useForm, useFieldArray, Controller } from 'react-hook-form';
import { useSetExtraMutation, useSetSpendMutation, useSetTableMutation } from '../../../api/shashlychokApi';
import { Spend } from '../../../models/shashlychok/Spend';
import useToast from '../../../hooks/useToast';


type ExtraItem = {
    id: number;
    name: string;
    quantity: number;
    price: number;
};

type FormValues = {
    items: ExtraItem[];
};

const formatDate = (dateString: string): string => {
  const date = parseISO(dateString);
  return format(date, 'dd.MM.yyyy');
};


const calculateIncome = (sessionItems: any[], prevSessionItems: Item[]) => {
  return sessionItems.reduce((total, item, index) => {
    const prevQuantity = prevSessionItems?.[index]?.quantityRemained || 0;
    const soldQuantity = prevQuantity + item.quantityArrival - item.quantityRemained;
    return total + soldQuantity * prices[index];
  }, 0);
};


const findItemValue = (name: string, session: Session | undefined) => {
    if (!session) {
        return 0
    }
    const item = session?.spend?.find((value, id) => value.name === name)
    return item?.value || 0
}

const excludedNames = ["Остаток на кассе", "Прибыль", "Расхождение", "Расход"];

const calculateTotalSpend = (data: SpendDto) => {
    const totalSpend = Object.keys(data).reduce((total, val) => { return total + Number(data[val as keyof SpendDto])}, 0)
    
    return totalSpend
}

const calculateInitialSpend = (spendItems: Spend[] | undefined) => {
    return spendItems
        ?.filter((val) => !excludedNames.includes(val.name))
        .reduce((total, item) => {
            return total + (item?.value || 0);
        }, 0);
}

const allSpendItems = [
    "QR доставка", "Kaspi (предоплаты)", "Kaspi pay", "БЦК", "Инкассация 1", "Инкассация 2",
    "Такси персонал", "Штраф", "Услуги курьера", "Супермаркет", "Шампура возврат", "Под З/П", 
    "Скидка", "Прочее", "Яндекс", "Wolt", "Списание", "Чокофуд", "Пицца", "Акция", "Онлайн оплата с сайта"
];

const excludeExtraItems = [
    "Тары Чай",
    "Ташкентский Чай",
    "Облепиховый Чай",
    "Чай Черный",
    "Чай Зеленый"
]

const calcSoldQuantity = (prevItems: Item[] = [], currentItems: Item[] = [], itemName: string): number => {
    const prevItem = prevItems.find(item => item.name === itemName);
    const currentItem = currentItems.find(item => item.name === itemName);

    if (!prevItem || !currentItem) {
        return 0;
    }

    return prevItem.quantityRemained + currentItem.quantityArrival - currentItem.quantityRemained;
};

interface SessionInfoProps {
    filial?: string;
    session?: {
      session: Session;
      prevSession: Session;
    };
}

interface SpendDto {
    [key: string]: number
}

interface TableData {
    items: {
        id: string;
        quantityArrival: number;
        quantityRemained: number;
    }[];
}

const SessionInfo: FC<SessionInfoProps> = ({ session: data }) => {
    const { session, prevSession } = data || {};
    const sortedItems = sortItemsByMenuOrder(session?.items, menuItems);
    const sortedPrevItems = sortItemsByMenuOrder(prevSession?.items, menuItems)

    const [income, setIncome] = useState<number>(0)
    const [extraIncome, setExtraIncome] = useState<number>(0)
    const [totalSpend, setSpend] = useState<number>(0)
    const [preset, setPreset] = useState<any[]>([])

    const [spend, {isLoading}] = useSetSpendMutation()
    const [extra, {isLoading: loading}] = useSetExtraMutation()
    const [table, {isLoading: tableLoading}] = useSetTableMutation()
    const {register, handleSubmit, setValue, formState: {errors}} = useForm<SpendDto>()
    const [spendMap, setSpendMap] = useState(new Map());

    const { control, handleSubmit: submit, reset } = useForm<FormValues>({
        defaultValues: { items: preset },
    });
    
    const { fields } = useFieldArray({
        control,
        name: 'items',
    });

    const { handleSubmit: tableSubmit, control: tableControl, watch, getValues, reset: tableReset } = useForm<TableData>({
        defaultValues: {
            items: sortedItems.map(item => ({
                id: item?.id,
                quantityArrival: item?.quantityArrival || 0,
                quantityRemained: item?.quantityRemained || 0,
            })),
        },
    });
    const watchedItems = watch("items");

    const [isShaking, setShaking] = useState<boolean>(false);
    const { showToast, ToastComponent } = useToast();

    const shake = () => {
        setShaking(true);

        setTimeout(() => {
            setShaking(false);
        }, 1000);
    };

    // Update spendMap when session changes
    useEffect(() => {
        const newSpendMap = new Map(session?.spend?.map(item => [item.name, item.value]));
        setSpendMap(newSpendMap);

        // Update form values based on the new spendMap
        allSpendItems.forEach(name => {
            setValue(name, newSpendMap.get(name) || 0);
        });
    }, [session, setValue, allSpendItems]);

    useEffect(() => {
        let initIncome = prevSession ? calculateIncome(sortedItems, sortedPrevItems) : 0;
        if (session?.extra) {
            let extraIncome = session.extra.filter(item => !excludeExtraItems.includes(item.name)).reduce((acc, item) => {
                return acc + item.quantity * item.price;
            }, 0) 
            setExtraIncome(extraIncome)
        }

        let initSpend: number | undefined = calculateInitialSpend(session?.spend)
        
        setIncome(initIncome)
        setSpend(initSpend || 0)
        
        if (session?.extra && session.extra.length > 0) {
            let extraArr = [...session.extra]
            if (!extraArr.find(obj => obj.name === 'Пицца')) 
                extraArr.push({ name: 'Пицца', quantity: 0, price: 0})
            
            if (!extraArr.find(obj => obj.name === 'Чай')) 
                extraArr.push({ name: 'Чай', quantity: 1, price: 0})

            if (!extraArr.find(obj => obj.name === 'Касса +')) 
                extraArr.push({ name: 'Касса +', quantity: 1, price: 0})

            const items = sortItemsByMenuOrder(extraArr.filter(item => !excludeExtraItems.includes(item.name)), extra_items)
            console.log("items:", items)
            reset({items})
        } else {
            const updatedPreset = [
                { name: 'Донер l', quantity: 0, price: 325 },
                { name: 'Донер XL', quantity: 0, price: 250 },
                { name: 'Лимонад', quantity: 0, price: 1640 },
                { name: 'Пицца', quantity: 0, price: 0},
                { name: 'Доставка 1', quantity: 0, price: 1000 },
                { name: 'Доставка 2', quantity: 0, price: 2000 },
                { name: 'Чай', quantity: 1, price: 0 },
                // { name: 'Ташкентский Чай', quantity: calcSoldQuantity(prevSession?.items, session?.items, 'Ташкентский Чай'), price: 0 },
                // { name: 'Облепиховый Чай', quantity: calcSoldQuantity(prevSession?.items, session?.items, 'Облепиховый Чай'), price: 0 },
                // { name: 'Чай Черный', quantity: calcSoldQuantity(prevSession?.items, session?.items, 'Чай Черный'), price: 0 },
                // { name: 'Чай Зеленый', quantity: calcSoldQuantity(prevSession?.items, session?.items, 'Чай Зеленый'), price: 0 },
                { name: 'Уголь', quantity: calcSoldQuantity(prevSession?.items, session?.items, 'Уголь'), price: 0 },
                { name: 'Касса +', quantity: 1, price: 0 }
            ];
            // Update the form's default values
            reset({ items: updatedPreset });
        }
    }, [data])

    useEffect(() => {
        tableReset({
            items: sortedItems.map(item => ({
                id: item.id,
                quantityArrival: item.quantityArrival || 0,
                quantityRemained: item.quantityRemained || 0,
            })),
        });
    }, [data, tableReset]);
    
    const submitSpend: SubmitHandler<SpendDto> = async (data) => {
        try {
            let totalSpend: number | undefined = calculateTotalSpend(data)
            
            await spend({
                ...data, 
                sessionId: session?.id,
            }).unwrap()
            
            setSpend(totalSpend || 0)
        } catch(e: any) {
            shake()
            showToast(e?.data?.message, {error: true})
        }
    }

    const submitExtra = async (data: FormValues) => {
        try {
            await extra({items: data.items, sessionId: session?.id}).unwrap()
            
            let extraIncome = data.items.reduce((acc, item) => {
                return acc + item.quantity * item.price;
            }, 0)
            
            setExtraIncome(extraIncome)
        } catch(e: any) {   
            showToast(e?.data?.message, {error: true})
        }
    }

    const submitTable = async (data: TableData) => {
        try {
            // // Calculate total income
            await table(data.items).unwrap()
            const totalIncome = calculateIncome(data.items, sortedPrevItems)
            
            setIncome(totalIncome)
        } catch (error: any) {
            showToast(error?.data?.message, {error: true})
            console.error("There was an error submitting the form!", error);
        }
    };

    return (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div className='col-span-2 lg:col-span-1'>
                <div className="flex flex-col space-y-6 bg-slate-900 p-6 lg:p-8  rounded-[20px] shadow h-full">
                <Title className="text-white" large>
                    Общие данные
                </Title>
                {session ? (
                    <ul className="flex flex-col space-y-2">
                    <li className="flex justify-between items-center">
                        <span className="text-white text-lg font-medium">Филиал:</span>
                        <span className="text-white text-lg">{locations[session.userId - 2]}</span>
                    </li>
                    <li className="flex justify-between items-center">
                        <span className="text-white text-lg font-medium">Дата:</span>
                        <span className="text-white text-lg">{formatDate(session.date)}</span>
                    </li>
                    <li className="flex justify-between items-center">
                        <span className="text-white text-lg font-medium">Завершена:</span>
                        <span className="text-white text-lg">{session.closed ? 'Да' : 'Нет'}</span>
                    </li>
                    <li className="flex justify-between items-center">
                        <span className="text-white text-lg font-medium">Открыта кем:</span>
                        <span className="text-white text-lg">{session.cashierOpened}</span>
                    </li>
                    <li className="flex justify-between items-center">
                        <span className="text-white text-lg font-medium">Закрыта кем:</span>
                        <span className="text-white text-lg">{session.cashierClosed}</span>
                    </li>
                    <li className="flex justify-between items-center">
                        <span className="text-white text-lg font-medium">Остаток на кассе в начале:</span>
                        <span className="text-white text-lg">{prevSession?.cash || '0'} тг</span>
                    </li>
                    <li className="flex justify-between items-center">
                        <span className="text-white text-lg font-medium">Остаток на кассе в конце:</span>
                        <span className="text-white text-lg">{session.cash} тг</span>
                    </li>
                    </ul>
                ) : (
                    <div className="flex items-center justify-center h-full">
                    <Spinner medium />
                    </div>
                )}
                </div>
            </div>
            <div className='col-span-2 lg:col-span-1'>
                <div className="flex flex-col space-y-8 bg-slate-900 p-6 lg:p-8  rounded-[20px] shadow h-full">
                    <Title className="text-white" large>
                        Итоги
                    </Title>
                    {
                        session ?
                        <div className='flex flex-col w-full'>
                            <div className='flex items-center justify-between'>
                                <p className='text-white text-xl lg:text-2xl'>Выручка c продаж:</p>
                                <p className='text-white text-xl lg:text-2xl'>{(income + extraIncome).toLocaleString('ru').replace(/,/g, ' ')} тг</p>
                            </div>
                            <div className='flex items-center justify-between mt-8'>
                                <p className='text-white text-xl lg:text-2xl'>Расход:</p>
                                <p className='text-white text-xl lg:text-2xl'>{totalSpend?.toLocaleString('ru').replace(/,/g, ' ')} тг</p>
                            </div>
                            <div className='bg-white w-full h-[1px] mt-10'></div>
                            <div className='flex items-center justify-between mt-4'>
                                <p className='text-white font-semibold text-2xl lg:text-3xl'>Расхождение:</p>
                                <p className='text-white text-2xl lg:text-3xl'>{`${
                                    income + extraIncome + (prevSession?.cash || 0) - totalSpend - (session?.cash || 0) > 0 
                                    ? `-${Math.abs(income + extraIncome + (prevSession?.cash || 0) - totalSpend - (session?.cash || 0)).toLocaleString('ru').replace(/,/g, ' ')}`
                                    : `+${Math.abs(income + extraIncome + (prevSession?.cash || 0) - totalSpend - (session?.cash || 0)).toLocaleString('ru').replace(/,/g, ' ')}`
                                } тг`}</p>
                            </div>
                        </div>
                    :
                    <div className="flex items-center justify-center h-full">
                        <Spinner medium />
                    </div>
                    }
                </div>
            </div>
            <div className="col-span-2">
                <div className="flex flex-col space-y-6 bg-slate-900 p-6 lg:p-8 rounded-[20px] shadow h-full">
                <Title className="text-white" large>
                    Расходы
                </Title>
                {session ? (
                    <Form onSubmit={handleSubmit(submitSpend)}>
                        <div className='grid grid-cols-2 lg:grid-cols-4 gap-6'>
                            {allSpendItems.map(name => {
                                const value = spendMap.get(name) || 0; // Get the value from the map or default to 0

                                return (
                                    <Form.TextField
                                        key={name} // Add a key prop for each item
                                        {...register(name, {
                                            value,
                                            valueAsNumber: true,
                                            validate: value => value >= 0 || 'Значение должно быть неотрицательным.'
                                        })}
                                        type='number'
                                        error={errors[name]}
                                        placeholder='Введите сумму'
                                        theme='dark'
                                        defaultValue={value}
                                        label={name}
                                    />
                                );
                            })}
                        </div>
                        <Form.FormButton
                            text='Применить'
                            theme='light'
                            isShaking={isShaking}
                            isLoading={isLoading}
                        />
                    </Form>
                ) : (
                    <div className="flex items-center justify-center h-full">
                    <Spinner medium />
                    </div>
                )}
                </div>
            </div>
            <div className="col-span-2">
                <div className="flex flex-col space-y-6 bg-slate-900 p-6 lg:p-8  rounded-[20px] shadow h-full">
                <Title className="text-white" large>
                    Товары
                </Title>
                {session ? (
                    <Form onSubmit={tableSubmit(submitTable)}>
                    <div className="max-h-[85vh] block overflow-x-auto w-full">
                        <table className="text-left w-full">
                            <thead className="sticky top-0 left-0 z-10 text-xs text-gray-300 bg-slate-800 shadow-2xl rounded-xl">
                                <tr>
                                    <th scope="col" className="pl-2 pr-8 lg:py-3 text-lg">Название</th>
                                    <th scope="col" className="px-8 lg:py-3 text-lg">Начало</th>
                                    <th scope="col" className="px-8 lg:py-3 text-lg">Прибытие</th>
                                    <th scope="col" className="px-8 lg:py-3 text-lg">Остаток</th>
                                    <th scope="col" className="px-8 lg:py-3 text-lg">Продано</th>
                                    <th scope="col" className="px-8 lg:py-3 text-lg">Выручка</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedItems.map((item, index) => {
                                    const prevQuantity = sortedPrevItems[index].quantityRemained || 0;
                
                                    // Safely access the watchedItems values
                                    const quantityArrival = watchedItems?.[index]?.quantityArrival ?? item.quantityArrival;
                                    const quantityRemained = watchedItems?.[index]?.quantityRemained ?? item.quantityRemained;
                                    
                                    const soldQuantity = prevQuantity + quantityArrival - quantityRemained;
                                    const income = soldQuantity * prices[index];
                
                                    return (
                                        <tr key={item.id} className="bg-transparent border-b w-full">
                                            <th scope="row" className="pl-3 pr-8 py-3 lg:py-6 text-gray-300 whitespace-nowrap dark:text-white">
                                                {item.name}
                                            </th>
                                            <td className="px-8 py-3 lg:py-6 text-gray-400 text-lg">{prevQuantity}</td>
                                            <td className="px-8 py-3 lg:py-6 text-gray-400">
                                                <Controller
                                                    name={`items.${index}.quantityArrival`}
                                                    control={tableControl}
                                                    render={({ field }) => (
                                                        <Form.TextField
                                                            {...field}
                                                            label=''
                                                            theme='dark'
                                                            type="number"
                                                            value={field.value === undefined ? '' : field.value}
                                                            onChange={(e) => field.onChange(e.target.value === '' ? '' : Number(e.target.value))}
                                                        />
                                                    )}
                                                />
                                            </td>
                                            <td className="px-8 py-3 lg:py-6 text-gray-400">
                                                <Controller
                                                    name={`items.${index}.quantityRemained`}
                                                    control={tableControl}
                                                    render={({ field }) => (
                                                        <Form.TextField
                                                            {...field}
                                                            label=''
                                                            theme='dark'
                                                            type="number"
                                                            defaultValue={item.quantityRemained}
                                                            value={field.value === undefined ? '' : field.value}
                                                            onChange={(e) => field.onChange(e.target.value === '' ? '' : Number(e.target.value))}
                                                        />
                                                    )}
                                                />
                                            </td>
                                            <td className="px-8 py-3 lg:py-6 text-gray-400">{soldQuantity}</td>
                                            <td className="px-8 py-3 lg:py-6 text-gray-400">{income} тг</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <Form.FormButton
                        type='submit'
                        text='Применить'
                        theme='light'
                        isLoading={tableLoading}
                    />
                </Form>
                ) : (
                    <div className="flex items-center justify-center h-full">
                    <Spinner medium />
                    </div>
                )}
                </div>
            </div>
            <div className="col-span-2">
                <div className="flex flex-col space-y-6 bg-slate-900 p-6 lg:p-8 rounded-[20px] shadow h-full">
                    <Title className="text-white" large>
                        Дополнительные товары
                    </Title>
                    {
                        session ? (
                            <Form onSubmit={submit(submitExtra)}>
                                {fields
                                    .filter((item) => !excludeExtraItems.includes(item.name))
                                    .map((obj, index) => (
                                    <div
                                        className="flex flex-col space-y-4 mb-5 lg:grid lg:grid-cols-5"
                                        key={obj.name}
                                    >
                                        <Form.Heading className="text-slate-300 lg:col-span-2" small>
                                        {obj.name}
                                        </Form.Heading>
                                        <div className="flex items-end space-x-6 justify-between lg:col-span-3 w-full lg:pr-10">
                                            {/* Conditional Rendering */}
                                            {(obj.name !== 'Чай' && obj.name !== 'Касса +') && (
                                                <Controller
                                                name={`items.${index}.quantity`}
                                                control={control}
                                                render={({ field }) => (
                                                    <Form.TextField
                                                    {...field}
                                                    theme="dark"
                                                    label="Количество продано"
                                                    type="number"
                                                    onChange={(e) => field.onChange(parseInt(e.target.value, 10))}
                                                    />
                                                )}
                                                />
                                            )}

                                            <Controller
                                                name={`items.${index}.price`}
                                                control={control}
                                                render={({ field }) => (
                                                    <Form.TextField
                                                        {...field}
                                                        theme="dark"
                                                        label="Стоимость"
                                                        type="number"
                                                        className={obj.name === 'Чай' ? 'w-full' : ''}
                                                        onChange={(e) => field.onChange(parseInt(e.target.value, 10))}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    ))}
                                <Form.FormButton text="Применить" theme="light" isLoading={loading} />
                            </Form>
                        ) : (
                            <div className="flex items-center justify-center h-full">
                                <Spinner medium />
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default SessionInfo;