import React, { FC, useEffect, useState } from 'react';
import { Table } from 'antd';
import { GroupedOrders } from './types';

interface MainTableProps {
    groupedOrders: GroupedOrders[];
}

const MainTable: FC<MainTableProps> = ({ groupedOrders }) => {
    const [dataWithSummary, setDataWithSummary] = useState<GroupedOrders[]>([]);

    // Function to calculate totals for the summary row
    const calculateTotals = (data: GroupedOrders[]) => {
        const totals: GroupedOrders = {
            address: 'Итоги', // "Totals" in Russian
            totalSales: 0,
            totalRefunds: 0,
            totalPromotions: 0,
            totalOrders: 0,
            successfulOrders: 0,
            unsuccessfulOrders: 0,
        };

        data.forEach((record) => {
            totals.totalSales += Number(record.totalSales) || 0;
            totals.totalRefunds += Number(record.totalRefunds) || 0;
            totals.totalPromotions += Number(record.totalPromotions) || 0;
            totals.totalOrders += Number(record.totalOrders) || 0;
            totals.successfulOrders += Number(record.successfulOrders) || 0;
            totals.unsuccessfulOrders += Number(record.unsuccessfulOrders) || 0;
        });

        return totals;
    };

    // Add the totals row to the data
    useEffect(() => {
        const totals = calculateTotals(groupedOrders);
        const dataWithTotals = [...groupedOrders, totals];
        setDataWithSummary(dataWithTotals);
    }, [groupedOrders]);
    
    const columns = [
        {
            title: 'Адрес',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Всего продаж',
            dataIndex: 'totalSales',
            key: 'totalSales',
            render: (value: number) => value.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        },
        {
            title: 'Всего возвратов',
            dataIndex: 'totalRefunds',
            key: 'totalRefunds',
            render: (value: number) => value.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        },
        {
            title: 'Акции',
            dataIndex: 'totalPromotions',
            key: 'totalPromotions',
            render: (value: number) => value.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        },
        {
            title: 'Всего заказов',
            dataIndex: 'totalOrders',
            key: 'totalOrders',
        },
        {
            title: 'Успешные заказы',
            dataIndex: 'successfulOrders',
            key: 'successfulOrders',
        },
        {
            title: 'Неуспешные заказы',
            dataIndex: 'unsuccessfulOrders',
            key: 'unsuccessfulOrders',
        },
    ];

    return <Table columns={columns} dataSource={dataWithSummary} rowKey="address" pagination={false} />;
};

export default MainTable;