import { FC, useState } from 'react'
import { FiUser, FiUsers } from "react-icons/fi";
import { FaUserCircle } from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import { IoExitOutline } from "react-icons/io5";
import { HiDocumentArrowUp } from "react-icons/hi2";
import { Link, useNavigate } from 'react-router-dom'
import { userClosed } from '../../../store/reducers/userSlice'
import useAuth from '../../../hooks/useAuth'
import { useAppDispatch } from '../../../hooks/redux'
import { baseApi } from '../../../api/baseApi'


const Navbar: FC = () => {
	const navigate = useNavigate()
	const { isAdmin, isManager, user } = useAuth()
	const dispatch = useAppDispatch()

	const [dropdown, setDropdown] = useState(false)

	const toggleDropdown = () => setDropdown(prev => !prev)

	const logout = () => {
		dispatch(baseApi.util.resetApiState())
		dispatch(userClosed())
		localStorage.removeItem("access_token")
	}

	const openLink = (link: string) => {
		toggleDropdown()
		navigate(link)
	}

	return (
		<div className='py-3 px-4 w-full border-b border-gray-800'>
			<div className='flex justify-between items-center px-2'>
				<div className='flex items-center space-x-20'>
					<Link to={"/dashboard"} className='text-aqua border-b border-aqua text-2xl font-bold'>FINPEAK</Link>
					<div className='hidden lg:flex items-center justify-between space-x-12'>
						{
							isManager && 
							<Link to={"/dashboard"} className='text-white font-semibold hover:text-aqua'>
								Power BI
							</Link>
						}
						<Link to={"/statement"} className='text-white font-semibold hover:text-aqua'>Выписки</Link>
						{
							isManager && <Link to={"/employee"} className='text-white font-semibold hover:text-aqua'>Сотрудники</Link>
						}
						{
							(isAdmin || (isManager && user.companyId === 7)) && 
							<Link to={"/files"} className='text-white font-semibold hover:text-aqua'>Файлы</Link>
						}
						{
							isAdmin && (
								<Link to={"/admin"} className=' text-white font-semibold hover:text-aqua'>Админ панель</Link>
							)
						}
						{
							user.companyId === 10 &&
							<Link to={"/shashlychok"} className='text-white font-semibold hover:text-aqua'>Отчеты по смене</Link>
						}
						{
							user.companyId === 12 &&
							<Link to={"/chicken-food"} className='text-white font-semibold hover:text-aqua'>Агрегаторы</Link>
						}
					</div>
				</div>
				<div className='relative'>
					<button onClick={toggleDropdown} className='hover:scale-105'>
						<FaUserCircle className='text-aqua' size={40}/>
					</button>
					
					<div className={`${dropdown ? "block" : "hidden"} absolute top-14 right-0 w-48 transition ease-in-out duration-150 overflow-hidden bg-white shadow rounded-[10px] z-50`}>
						<ul className='flex flex-col divide-y'>
							<li onClick={() => openLink("/profile")} className='flex items-center space-x-2 px-4 py-3 hover:cursor-pointer hover:bg-gray-100'>
								<FiUser size={20}/>
								<span className='text-gray-800 font-medium'>Мои профиль</span>
							</li>
							{
								isManager && 
								<>
									<li onClick={() => openLink("/dashboard")} className='flex lg:hidden items-center space-x-2 px-4 py-3 hover:cursor-pointer hover:bg-gray-100'>
										<MdDashboard size={20} />
										<span className='text-gray-800 font-medium'>Power BI</span>
									</li>
									<li onClick={() => openLink("/employee")} className='flex lg:hidden items-center space-x-2 px-4 py-3 hover:cursor-pointer hover:bg-gray-100'>
										<FiUsers size={20} />
										<span className='text-gray-800 font-medium'>Сотрудники</span>
									</li>
								</>
							}
							{
								(isAdmin || (isManager && user.companyId === 8)) && 
								<li onClick={() => openLink("/files")} className='lg:hidden text-gray-800 font-medium px-4 py-3 hover:cursor-pointer hover:bg-gray-100'>Файлы</li>
							}
							<li onClick={() => openLink("/statement")} className='flex lg:hidden items-center space-x-2 px-4 py-3 hover:cursor-pointer hover:bg-gray-100'>
								<HiDocumentArrowUp size={20} />
								<span className='text-gray-800 font-medium'>Выписки</span>
							</li>
							{
								isAdmin && (
									<li onClick={() => openLink("/admin")} className='lg:hidden text-gray-800 font-medium px-4 py-3 hover:cursor-pointer hover:bg-gray-100'>Админ панель</li>
								)
							}
							{
								user.companyId === 10 &&
								<li onClick={() => openLink("/shashlychok")} className='lg:hidden text-gray-800 font-medium px-4 py-3 hover:cursor-pointer hover:bg-gray-100'>Отчеты по смене</li>
							}
							{
								user.companyId === 7 &&
								<li onClick={() => openLink("/files")} className='lg:hidden text-gray-800 font-medium px-4 py-3 hover:cursor-pointer hover:bg-gray-100'>Файлы</li>
							}
							{
								user.companyId === 12 &&
								<li onClick={() => openLink("/chicken-food")} className='lg:hidden text-gray-800 font-medium px-4 py-3 hover:cursor-pointer hover:bg-gray-100'>Агрегаторы</li>
							}
							<li className='flex items-center space-x-2 px-4 py-3 hover:cursor-pointer hover:bg-gray-100' onClick={logout}>
								<IoExitOutline className='text-red-500' size={20}/>
								<p className='font-semibold text-red-500'>Выйти</p>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Navbar