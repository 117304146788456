import React, { ButtonHTMLAttributes, FC } from 'react'
import Spinner from '../Spinner'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    text: string
    theme: "light" | "dark"
    isShaking?: boolean
    isLoading?: boolean
}

const Button: FC<ButtonProps> = ({text, theme, isLoading, isShaking, ...rest}) => {
    return (
        <button 
            className={`flex w-full lg:w-auto items-center justify-center px-10 py-3 rounded-3xl shadow font-semibold border-2 ${theme === 'dark' ? 'bg-dark text-white hover:text-black hover:bg-white hover:border-black' : 'bg-transparent text-white border-white hover:text-black hover:bg-white'} ${isShaking && 'animate-shake'}`}
            {...rest}
            disabled={isLoading}
            >
            {
                isLoading ? <Spinner/> : <span>{text}</span>
            }
        </button>
    )
}

export default Button