import Navbar from '../Navbar'
import {Outlet, Navigate} from 'react-router-dom'
import { useAppSelector } from '../../../hooks/redux'
import React, { FC } from 'react'
import Spinner from '../../UI/Spinner'

const Layout: FC = () => {
    const isLoggedIn = useAppSelector(state => state.userReducer.isLoggedIn)

    if (!isLoggedIn) {
        return (
            <Navigate to={"/auth"} replace />
        )
    }

    return (
        <div className='h-screen flex flex-col'>
            <Navbar/>
            <React.Suspense fallback={<div className='px-4 md:px-14 py-8'><Spinner/></div>}>
                <div className='flex-grow'>
                    <Outlet/>
                </div>
            </React.Suspense>
        </div>
    )
}

export default Layout