import React, { FC, useEffect, useState } from 'react'
import Sidebar from '../../components/layout/Sidebar'
import SidebarLink from '../../components/SidebarLink'
import { FaHeart, FaYandex } from 'react-icons/fa'
import { Outlet, useLocation } from 'react-router-dom'
import { TbTruckDelivery } from 'react-icons/tb'
import {SiGlovo} from 'react-icons/si'


const ChickenFood: FC = () => {
    const location = useLocation()
    const [currentLink, setCurrentLink] = useState("/chicken-food")

    useEffect(() => {
        setCurrentLink(location?.pathname)
    }, [])

    return (
        <div className='p-4 md:p-12'>
            <div className='text-3xl text-white font-semibold mb-8 md:mb-12'>Агрегаторы</div>
            <div className='grid grid-cols-1 lg:grid-cols-5 gap-10'>
                <div className='lg:col-span-1'>
                    <Sidebar>
                        <SidebarLink
                            to='/chicken-food'
                            text='Yandex'
                            setCurrentLink={() => setCurrentLink("/chicken-food")}
                            currentLink={currentLink}
                            icon={<FaYandex className='shrink-0 text-slate-200' size={27} />}
                        />
                        <SidebarLink
                            to='/chicken-food/wolt'
                            text='Wolt'
                            setCurrentLink={() => setCurrentLink("/chicken-food/wolt")}
                            currentLink={currentLink}
                            icon={<TbTruckDelivery className='shrink-0 text-slate-200' size={27} />}
                        />
                        <SidebarLink
                            to='/chicken-food/glovo'
                            text='Glovo'
                            setCurrentLink={() => setCurrentLink("/chicken-food/glovo")}
                            currentLink={currentLink}
                            icon={<SiGlovo className='shrink-0 text-slate-200' size={27} />}
                        />
                        <SidebarLink
                            to='/chicken-food/choco'
                            text='Chocofood'
                            setCurrentLink={() => setCurrentLink("/chicken-food/choco")}
                            currentLink={currentLink}
                            icon={<FaHeart className='shrink-0 text-slate-200' size={27} />}
                        />
                    </Sidebar>
                </div>
                <div className='lg:col-span-4'>
                    <Outlet/>
                </div>
            </div>
        </div>
    )
}

export default ChickenFood