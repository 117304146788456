export const API_URL: string = process.env.API_URL || "https://api.finpeak.kz/api"
export const menuItems = [
    "Баранина Шашлык",
    "Антрекот Шашлык",
    "Говядина Шашлык",
    "Окорочка Шашлык",
    "Картофель С Курдюком Шашлык",
    "Наполеон Шашлык",
    "Крылышки Шашлык",
    "Рулет Из Говядины Шашлык",
    "Люля Кебаб Шашлык",
    "Филе Куриное Шашлык",
    "Индейка Шашлык",
    "Утка Шашлык",
    "Овощное Ассорти Шашлык",
    "Шампиньоны Шашлык",
    "Филе Окорочка Для Донера Сырой",
    "Фирм Беспанжа",
    "Седло Стейк",
    "Ребра Шашлык",
    "Кукуруза Шашлык",
    "Готовый Люля Для Донера",
    "Готовый Окорочка Для Донера",
    "Лаваш 35мм",
    "Лепешка",
    "Соус Белый",
    "Соус Красный",
    "Медово-Горчичный",
    "Соус Фирменный",
    "Соус Сырный",
    "Халапеньо",
    "Кетчуп",
    "Фри Порция",
    "Наггетсы",
    "Дольки",
    "Ачичук Салат",
    "Салат Цезарь",
    "Салат Греческий",
    "Овощная Нарезка",
    "Чак Чак",
    "Десерты",
    "Пахлава",
    "Мороженое",
    "Игрушка",
    "Пепси 1 Литр",
    "Пепси 0,5",
    "Миринда 1 Литр",
    "Миринда 0,5",
    "7 Up 1 Литр",
    "7 Up 0,5",
    "Липтон 1 Литр",
    "Липтон 0,5",
    "Асу 1 Литр",
    "Асу 0,5",
    "Айран",
    "Сок 1 Литр",
    "Сок 0,2",
    "Жб Грацио",
    "Стекло Грацио",
    "Кофе 3 в 1",
    "Тары Чай",
    "Ташкентский Чай",
    "Облепиховый Чай",
    "Чай Черный",
    "Чай Зеленый",
    "Уголь"
];

export const locations = [
    "Астана Молл",
    "Рахмет Малый",
    "Рахмет Большой",
    "Евразия",
    "Магнум",
    "Сыганак",
    "Фудтрак",
    "Экспострой",
    "Жетису",
    "Жетису 2",
    "Косшы",
    "Столичный парк",
    "Туркестан",
    "Артем",
    "Кенмарт",
    "Жаннур малый",
    "Жаннур новый",
    "Алатау",
    "Анвар",
    "Туран",
    "Туран доставка",
    "Аль Фараби",
    "Турам"
]

export const extra_items = [
    'Донер l',
    'Донер XL',
    'Лимонад',
    'Пицца',
    'Доставка 1',
    'Доставка 2',
    'Чай',
    'Уголь',
    'Касса +'
];

export const prices = [
    1540,
    1540,
    1490,
    1190,
    790,
    1740,
    1090,
    1640,
    1290,
    1240,
    1490,
    1290,
    790,
    1090,
    1190,
    3390,
    1850,
    1490,
    790,
    1290,
    1190,
    150,
    300,
    150,
    150,
    250,
    250,
    190,
    150,
    100,
    600,
    700,
    600,
    890,
    890,
    890,
    890,
    690,
    590,
    350,
    790,
    800,
    800,
    550,
    800,
    550,
    800,
    550,
    800,
    550,
    600,
    400,
    450,
    1000,
    400,
    700,
    690,
    250,
    0,
    0,
    0,
    0,
    0,
    0
]
