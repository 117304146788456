import React, { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'


interface SidebarLinkProps {
    text: string
    setCurrentLink: () => void
    icon?: ReactNode
    to: string
    currentLink: string
}

const SidebarLink: FC<SidebarLinkProps> = ({text, setCurrentLink, icon, to, currentLink}) => {
    return (
        <Link to={to} onClick={setCurrentLink} className={`rounded-2xl px-6 py-3 lg:py-4 flex items-center justify-center min-w-[160px] lg:justify-start space-x-3 ${currentLink === to ? 'bg-blue-300 shadow-xl' : 'hover:bg-gray-800'}`}>
            {icon}
            <span className='font-semibold text-slate-100 md:text-lg'>{text}</span>
        </Link>
    )
}

export default SidebarLink