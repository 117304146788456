import React from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';


interface CancelledOrder {
    cancelReason: string;
    address: string;
    paymentStatus: string;
    totalPrice: number;
    totalCommission: number;
    orderCount: number;
}

interface CancelledProps {
    cancelledOrders: CancelledOrder[];
}
  
const CancelledOrdersTable: React.FC<CancelledProps> = ({ cancelledOrders }) => {
    const numberFormatter = new Intl.NumberFormat('ru-RU', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    });

    const columns: ColumnsType<CancelledOrder> = [
        {
            title: 'Адрес',
            dataIndex: 'address',
            key: 'address',
            filters: Array.from(new Set(cancelledOrders.map(order => order.address))).map(address => ({ text: address, value: address })),
            onFilter: (value, record) => record.address === value,
        },
        {
        title: 'Причина отмены',
        dataIndex: 'cancelReason',
        key: 'cancelReason',
        filters: Array.from(new Set(cancelledOrders.map(order => order.cancelReason))).map(address => ({ text: address, value: address })),
        onFilter: (value, record) => record.cancelReason === value,
        },
        {
        title: 'Статус оплаты',
        dataIndex: 'paymentStatus',
        key: 'paymentStatus',
        filters: Array.from(new Set(cancelledOrders.map(order => order.paymentStatus))).map(address => ({ text: address, value: address })),
        onFilter: (value, record) => record.paymentStatus === value,
        },
        {
        title: 'Общая сумма',
        dataIndex: 'totalPrice',
        key: 'totalPrice',
        render: (value) => numberFormatter.format(value),
        sorter: (a, b) => a.totalPrice - b.totalPrice,
        },
        {
        title: 'Общая комиссия',
        dataIndex: 'totalCommission',
        key: 'totalCommission',
        render: (value) => numberFormatter.format(value),
        sorter: (a, b) => a.totalCommission - b.totalCommission,
        },
        {
        title: 'Количество заказов',
        dataIndex: 'orderCount',
        key: 'orderCount',
        render: (value) => numberFormatter.format(value),
        sorter: (a, b) => a.orderCount - b.orderCount,
        },
    ];

    return <Table columns={columns} dataSource={cancelledOrders} rowKey="id" />;
};

export default CancelledOrdersTable;