import { forwardRef, InputHTMLAttributes, ReactNode, RefObject, useState } from 'react'
import { FieldError } from 'react-hook-form'
import { VscEye } from "react-icons/vsc";
import { VscEyeClosed } from "react-icons/vsc";


export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string
    theme: "light" | "dark"
    error?: FieldError | undefined
    subtitle?: ReactNode
    toggle?: boolean
    mask?: string
}

const Input = forwardRef<HTMLInputElement, InputProps>(({
    label, 
    theme, 
    subtitle,
    error,
    toggle,
    mask="",
    type = "text",
    ...rest }, ref) => 
{

    const [visible, setVisible] = useState<boolean>(false)

    const toggleVisibility = () => setVisible(prev => !prev)

    return (
        <div className="flex flex-col">
            <span className={`${theme === "light" ? "text-gray-700" : "text-gray-300"} text-lg lg:text-xl`}>{label}</span>
            <div className='relative'>
                <input 
                    ref={ref}
                    {...rest} 
                    type={(toggle && !visible) ? 'password' : type}
                    className={`mt-2 block tracking-wide bg-transparent w-full text-lg ${theme === 'light' ? 'text-gray-600' : 'text-gray-200'} outline-none px-1 py-1 border-0 border-b-2 border-gray-150 focus:ring-0 focus:border-aqua`} 
                /> 
                {toggle && 
                    <div className='absolute right-2 top-3'>
                        {
                            visible ? 
                            <VscEye 
                                className='text-blue-300 hover:cursor-pointer hover:scale-105' 
                                onClick={toggleVisibility} 
                                size={27}/> 
                            : 
                            <VscEyeClosed 
                                className='text-blue-300 hover:cursor-pointer hover:scale-105' 
                                onClick={toggleVisibility}
                                size={27}
                            />
                        }
                    </div>
                }
            </div>
            {(!error && subtitle) && <span className='text-gray-400 mt-2'>{subtitle}</span>}
            {error && <span className='text-red-400 mt-2'>{error.message}</span>}
        </div>
    )
})

export default Input