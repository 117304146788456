import { FC } from 'react'
import classNames from 'classnames'
import { MdOutlineClose } from "react-icons/md";

interface ToastProps {
    text: string
    open: boolean
    onHide: () => void
    success: boolean
    warning: boolean
    error: boolean
}

const Toast: FC<ToastProps> = ({
    text,
    open,
    onHide,
    success, warning, error}) => {
        
    const classess = classNames(`
        ${open ? 'block' : 'hidden'} 
        transition ease-in-out duration-300 shadow-xl 
        px-5 py-3 rounded-[10px] min-w-[200px] z-30`, 
        {
            'bg-green-500': success,
            'bg-orange-400': warning,
            'bg-red-500': error
        }
    )

    return (
        <div className='fixed top-4 right-4'>
            <div className={classess}>
                <div className='flex justify-between items-center space-x-6'>
                    <p className='text-white font-medium text-lg'>{text}</p>
                    <MdOutlineClose className='text-white' onClick={onHide} size={20} />
                </div>
            </div>
        </div>
    )
}

export default Toast