import { Title } from '../UI/Title'
import HistoryItem from '../HistoryItem'
import { convertDate } from '../../utils/utils'
import { useGetStatementsQuery } from '../../api/statementApi'
import { FC } from 'react'
import Spinner from '../UI/Spinner'

interface HistoryProps {
    data: any[]
    isLoading: boolean
}

const History: FC<HistoryProps> = ({ data, isLoading }) => {
    
    return (
        <div className='flex flex-col space-y-4'>
            <Title className='text-white' medium>История</Title>
            {
                isLoading ? <Spinner/>
                :
                <>
                    {
                        data && data.length > 0 ? (
                            <>
                                {
                                    data?.map((item: any) => (
                                        <HistoryItem
                                            key={item.date}
                                            date={convertDate(item?.date)}
                                            text={item?.text}
                                            type={item?.type}
                                            status={item?.status}
                                        />
                                    ))
                                }
                            </>
                        ) : (
                            <span className='text-gray-400 text-lg'>Пусто</span>
                        )
                    }   
                </>
            }
        </div>
    )
}

export default History