import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Layout from "./components/layout/DashboardLayout";
import Dashboard from "./pages/Dashboard";
import Auth from "./pages/Auth";
import useAuth from "./hooks/useAuth";
import AdminLayout from "./components/admin/AdminLayout";
import EmployeeLayout from "./components/layout/EmployeeLayout";
import Files from "./pages/Files";
import Shashlychok from "./pages/Shashlychok";
import ChickenFood from "./pages/ChickenFood";
import YandexForm from "./components/chicken-food/yandex/YandexForm";
import WoltForm from "./components/chicken-food/wolt/WoltForm";
import GlovoForm from "./components/chicken-food/glovo/GlovoForm";
import ChocoForm from "./components/chicken-food/choco/ChocoForm";


const Profile = React.lazy(() => import("./pages/Profile"));
const Statement = React.lazy(() => import("./pages/Statement"));
const BiPanels = React.lazy(() => import("./pages/Admin/BI-Panels"));
const ManageEmployee = React.lazy(() => import("./pages/Employee/Manage"));
const EmployeesManager = React.lazy(() => import("./pages/Employee/Table"));
const EmployeesAdmin = React.lazy(() => import("./pages/Admin/Employees"));
const Company = React.lazy(() => import("./pages/Admin/Company"));
const Sheets = React.lazy(() => import("./pages/Admin/Sheets"))


const App: React.FC = () => {

	const {isLoggedIn, isManager} = useAuth()

	return (
		<BrowserRouter>
			<Routes>
				<Route element={<Layout />}>
					<Route path='dashboard' element={<Dashboard />} />
					<Route path='profile' element={<Profile/>} />
					<Route path='statement' element={<Statement/>}/>
					<Route path="files" element={<Files/>} />
					<Route path="employee" element={<EmployeeLayout/>}>
						<Route index element={<EmployeesManager/>} />
						<Route path="add" element={<ManageEmployee/>} />
					</Route>
					<Route path='admin' element={<AdminLayout/>}>
						<Route index element={<Company/>} />
						<Route path='power-bi' element={<BiPanels/>} />
						<Route path="employees" element={<EmployeesAdmin/>} />
						<Route path="sheets" element={<Sheets/>} />
					</Route>
					<Route path="shashlychok" element={<Shashlychok/>} />
					<Route path="chicken-food" element={<ChickenFood/>}>
						<Route index element={<YandexForm/>} />
						<Route path="wolt" element={<WoltForm/>} />
						<Route path="glovo" element={<GlovoForm/>} />
						<Route path="choco" element={<ChocoForm/>} />
					</Route>
				</Route>
				
				<Route
					path='auth'
					element={<Auth/>}
				/>
				<Route path="*" element={<Navigate to={isLoggedIn ? isManager ? '/dashboard' : '/statement' : '/auth'} />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
