import { AddSheetDto } from '../models/dto/AddSheetDto'
import { CreateSheetDto } from '../models/dto/CreateSheetDto'
import { Sheet } from '../models/Sheet'
import { baseApi } from './baseApi'


export const sheetsApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        createSheet: builder.mutation<Sheet, CreateSheetDto>({
            query: (body) => ({
                url: "/admin/sheets",
                method: 'POST',
                body: body
            }),
            invalidatesTags: (_) => ["Employee"]
        }),
        addSheet: builder.mutation<Sheet, AddSheetDto>({
            query: (body) => ({
                url: "/sheets/add",
                method: 'POST',
                body: body
            }),
            invalidatesTags: (_) => ["Employee", "Sheets"]
        }),
        getSheets: builder.query<Sheet[], string>({
            query: () => "/sheets/all",
            providesTags: (_) => ["Sheets"]
        })
    })
})

export const {useCreateSheetMutation, useAddSheetMutation, useGetSheetsQuery} = sheetsApi