import {createSlice} from '@reduxjs/toolkit'
import { Employee } from '../../models/Employee'


interface UserState {
    isLoading: boolean
    isLoggedIn: boolean
    user: Employee 
    error: string
}

const initialState: UserState = {
    isLoading: false,
    isLoggedIn: false,
    user: {
        id: 0,
        name: '',
        login: '',
        companyId: 0,
        roles: [],
    },
    error: ""
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        userLoading: (state) => {
            state.isLoading = true;
        },

        userCreated: (state, action) => {
            state.isLoggedIn = true
            state.isLoading = false;
            state.error = '';
            state.user = action.payload;
        },

        userClosed: (state) => {
            return initialState;
        },

        userError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
})

export const { userLoading, userCreated, userClosed, userError } = userSlice.actions;

export default userSlice.reducer;