import React, { FC, Key } from 'react';
import { Table } from 'antd';
import { Retentions } from './types';
import { render } from '@testing-library/react';
import { ColumnType } from 'antd/es/table';

interface RetentionOrdersTableProps {
    retentions: Retentions[];
}

const numberFormatter = new Intl.NumberFormat('ru-RU', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
});

const RetentionOrdersTable: FC<RetentionOrdersTableProps> = ({ retentions }) => {
    const columns: ColumnType<Retentions>[] = [
        {
            title: 'Адрес',
            dataIndex: 'address',
            key: 'address',
            filters: Array.from(new Set(retentions.map(order => order.address)))
                .map(address => ({
                    text: address.trim(), // Trim the whitespace
                    value: address.trim().toLowerCase(), // Use lowercased values for uniform comparison
                })),
            onFilter: (value: any, record: Retentions) => {
                const normalizedAddress = record.address.trim().toLowerCase(); // Normalize the record value
                return normalizedAddress === (value as string); // Strict comparison
            },
        },
        {
            title: 'Причина удержания',
            dataIndex: 'reason',
            key: 'reason',
            filters: Array.from(new Set(retentions.map(order => order.reason))).map(reaosn => ({ text: reaosn, value: reaosn })),
            onFilter: (value: any, record: any) => record.reason === value,
        },
        {
            title: 'Сумма удержания',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            render: (value: number) => numberFormatter.format(value)
        },
    ];

    return <Table columns={columns} dataSource={retentions} rowKey="id" pagination={false} />;
};

export default RetentionOrdersTable;