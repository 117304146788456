import { FC, PropsWithChildren } from 'react'


const Sidebar: FC<PropsWithChildren> = ({children}) => {

    return (
        <div className='w-full'>
            <div className='flex flex-row space-x-6 lg:flex-col lg:space-x-0 lg:space-y-8 overflow-x-auto lg:mr-4'>
                {children}
            </div>
        </div>
    )
}

export default Sidebar