import { useState } from 'react';
import Toast from '../components/UI/Toast';


const useToast = () => {
    const [toastState, setToastState] = useState({
        isOpen: false,
        toastText: '',
        success: false,
        warning: false,
        error: false,
    });

    const showToast = (text: string, options: any = {}) => {
        const { success, warning, error } = options;

        setToastState({
            isOpen: true,
            toastText: text,
            success: success || false,
            warning: warning || false,
            error: error || false,
        });

        // Hide the toast after a few seconds
        setTimeout(() => {
            setToastState((prevState) => ({
                ...prevState,
                isOpen: false,
            }));
        }, 3000); // 3000 milliseconds (3 seconds)
    };

    const ToastComponent = () => (
        <Toast
            text={toastState.toastText}
            open={toastState.isOpen}
            success={toastState.success}
            warning={toastState.warning}
            error={toastState.error}
            onHide={() => setToastState((prevState) => ({ ...prevState, isOpen: false }))}
        />
    );

    return { showToast, ToastComponent };
};

export default useToast;