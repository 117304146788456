import React, { FC, useState, useEffect, useCallback } from 'react'
import { PowerBIEmbed } from 'powerbi-client-react';
import {useGetReportCredentialsQuery } from '../../api/reportApi';
import {models, Report, Embed} from 'powerbi-client'
import Spinner from '../UI/Spinner';
import { AiOutlineShrink } from 'react-icons/ai';
import { IoExpandOutline } from 'react-icons/io5';


const PowerBI: FC = () => {
    const {data, error, isLoading} = useGetReportCredentialsQuery("", {
        refetchOnMountOrArgChange: true
    })
    const [report, setReport] = useState<Report>();
    const [expanded, setExpanded] = useState(false)

	const escFunction = useCallback((event: any) => {
        if (event.key === "Escape") {
            setExpanded(false)
        }
    }, []);
    

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    if (isLoading) {
        return <Spinner/>
    }

    if (error) {
        if ('status' in error) {
            // you can access all properties of `FetchBaseQueryError` here
            const errMsg: any = 'error' in error ? error.error : error.data
      
            return (
                <span className='text-gray-300 mt-2'>{errMsg.message}</span>
            )
        } else {
            console.log(error)
        }
    }

    return (
        <>
            <div className={`${expanded ? 'absolute top-0 right-0 z-20' : 'flex justify-end'}`}>
                <div className={`px-2 py-1 rounded-md hover:bg-gray-900 hover:cursor-pointer`} 
                    onClick={() => setExpanded(prev => !prev)}>
                        {
                            expanded ? <AiOutlineShrink className='text-aqua' size={40} title='Уменьшить'/> : <IoExpandOutline className='text-aqua' size={30} title='Увеличить на весь экран'/>
                        }
                </div>
            </div>
            {
                data?.embedUrl && 
                <PowerBIEmbed
                    embedConfig = {{
                        type: 'report',   // Supported types: report, dashboard, tile, visual, qna, paginated report and create
                        id: data?.reportId,
                        embedUrl: data?.embedUrl,
                        accessToken: data?.accessToken,
                        tokenType: models.TokenType.Aad, // Use models.TokenType.Aad for SaaS embed
                        settings: {
                            panes: {
                                filters: {
                                    expanded: false,
                                    visible: false
                                },
                                pageNavigation: {
                                    visible: false,
                                }
                            },
                            localeSettings: {
                                language: "ru-RU",
                                formatLocale: "ru-RU"
                            },
                            layoutType: models.LayoutType.Custom,
                            customLayout: {
                                displayOption: expanded ? models.DisplayOption.FitToPage : models.DisplayOption.FitToWidth
                            },
                            //background: models.BackgroundType.Transparent
                        }
                    }}

                    eventHandlers = {
                        new Map([
                            ['loaded', function () {console.log('Report loaded');}],
                            ['rendered', function () {console.log('Report rendered');}],
                            ['error', function (event) {
                                console.log("Error")
                            }],
                            ['visualClicked', () => console.log('visual clicked')],
                            ['pageChanged', (event) => console.log(event)],
                        ])
                    }

                    cssClassName = { expanded ? "expand-container" : "embed-container"}

                    getEmbeddedComponent = { (embedObject: Embed) => {
                        setReport(embedObject as Report);
                    } }
                />
            }
        </>
    )
}

export default PowerBI