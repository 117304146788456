import { Account } from '../models/Account'
import { AddAccountDto } from '../models/dto/AddAccountDto'
import { baseApi } from './baseApi'


export const accountApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        addManagerAccount: builder.mutation<{message: string}, AddAccountDto>({
            query: (body) => ({
                url: '/account',
                method: 'POST',
                body: body
            }),
            invalidatesTags: (_) => ["Employee"]
        }),
        getCash: builder.mutation({
            query: (body) => ({
                url: '/sheets/wallets',
                method: 'POST',
                body: body
            })
        }),
    })
})


export const { useAddManagerAccountMutation, useGetCashMutation } = accountApi