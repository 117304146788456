import { baseApi } from './baseApi'


export const chickenFoodApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getYandexByDate: builder.mutation<any, {fromDate: string, toDate: string}>({
            query: (params) => {
                // Construct query string from params
                const queryParams = new URLSearchParams(params as any).toString();
                return `/chicken-food/yandex?${queryParams}`;
            },
        }),
        getWoltByDate: builder.mutation<any, {fromDate: string, toDate: string}>({
            query: (params) => {
                // Construct query string from params
                const queryParams = new URLSearchParams(params as any).toString();
                return `/chicken-food/wolt?${queryParams}`;
            },
        }),
        getGlovoByDate: builder.mutation<any, {fromDate: string, toDate: string}>({
            query: (params) => {
                // Construct query string from params
                const queryParams = new URLSearchParams(params as any).toString();
                return `/chicken-food/glovo?${queryParams}`;
            },
        }),
        getChocoByDate: builder.mutation<any, {fromDate: string, toDate: string}>({
            query: (params) => {
                // Construct query string from params
                const queryParams = new URLSearchParams(params as any).toString();
                return `/chicken-food/choco?${queryParams}`;
            },
        }),
    })
})


export const {useGetYandexByDateMutation, useGetWoltByDateMutation, useGetGlovoByDateMutation, useGetChocoByDateMutation} = chickenFoodApi