import { FC } from 'react'
import SalesReportForm from '../../components/forms/SalesReportForm'
import CashFileForm from '../../components/forms/CashFileForm'
import History from '../../components/History'
import { useGetFilesQuery } from '../../api/filesApi'
import useAuth from '../../hooks/useAuth'
import { Navigate } from 'react-router-dom'


const Files: FC = () => {
    const {user} = useAuth()
    const {data, isLoading} = useGetFilesQuery()

    if (user.companyId !== 7) {
        <Navigate to={"/"} replace />
    }

    return (
        <div className='py-10 px-4 md:px-14'>
            <div className='flex flex-col space-y-12'>
                <SalesReportForm/>
                {/* <CashFileForm/> */}
                <History data={data ?? []} isLoading={isLoading}/>
            </div>
        </div>
    )
}

export default Files