import React, { ReactNode, useState } from 'react';
import Select, { ActionMeta, SingleValue } from 'react-select';


export interface OptionType {
  label: string;
  value: string;
}

export interface SelectInputProps {
  label: string
  theme: "light" | "dark"
  error?: string | undefined
  subtitle?: ReactNode | string
  options: OptionType[];
  selectedOption: OptionType | undefined;
  onChange: (selectedOption: OptionType) => void;
}

const SelectInput: React.FC<SelectInputProps> = ({
  options,
  selectedOption,
  onChange,
  label,
  theme,
  error,
  subtitle
}) => {
    const handleChange = (newValue: SingleValue<OptionType>) => {
        onChange(newValue as OptionType);
    };

    return (
		<div className="flex flex-col">
			<span className={`${theme === "light" ? "text-gray-700" : "text-gray-300"} text-lg lg:text-xl mb-2`}>{label}</span>
			<Select
        		placeholder='Выберите'
				value={selectedOption}
				onChange={handleChange}
				options={options}
			/>
			{(!error && subtitle) && <span className='text-gray-400 mt-2'>{subtitle}</span>}
			{error && <span className='text-red-400 mt-2'>{error}</span>}
		</div>
	);
};

export default SelectInput
