import React, { FC } from 'react'
import SessionForm from '../../components/shashlychok/SessionForm'


const Shashlychok: FC = () => {

    return (
        <div className='py-10 px-4 md:px-14'>
            <div className='flex flex-col space-y-10'>
                <div className='text-4xl text-white font-bold mb-8'>Шашлычок Бот</div>
                <SessionForm/>
            </div>
        </div>
    )
}

export default Shashlychok