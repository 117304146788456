import { jwtDecode } from 'jwt-decode'
import { useAppSelector } from './redux'


const useAuth = () => {
    const {user, isLoggedIn, isLoading} = useAppSelector(state => state.userReducer)
    let decoded: any = {}

    const accessToken = localStorage.getItem('access_token');

    if (accessToken) {
        try {
            decoded = jwtDecode(accessToken)
        } catch (error) {
            console.error('Error decoding JWT token:', error);
        }
    }

    return {
        user,
        isLoggedIn,
        isLoading,
        roles: decoded?.roles,
        isManager: decoded.roles?.find((role: any) => role.value === "MANAGER"),
        isAdmin: decoded.roles?.find((role: any) => role.value === "ADMIN")
    }
}

export default useAuth