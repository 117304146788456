import { useEffect, useState } from 'react'
import {Outlet, Navigate, useLocation} from 'react-router-dom'
import Sidebar from '../../layout/Sidebar'
import useAuth from '../../../hooks/useAuth'
import { FiUsers } from "react-icons/fi";
import { MdDashboard } from "react-icons/md";
import SidebarLink from '../../SidebarLink'
import { PiHouseSimpleFill } from "react-icons/pi";
import { BiSolidSpreadsheet } from "react-icons/bi";


const AdminLayout = () => {
    const { isAdmin } = useAuth()
    const location = useLocation()
    const [currentLink, setCurrentLink] = useState("/admin")

    useEffect(() => {
        setCurrentLink(location?.pathname)
    }, [])

    if (!isAdmin) {
        return (
            <Navigate to={"/dashboard"} replace />
        )
    }

    return (
        <div className='p-4 md:p-12'>
            <div className='text-3xl text-white font-semibold mb-8 md:mb-12'>Админ панель</div>
            <div className='grid grid-cols-1 lg:grid-cols-5 gap-10'>
                <div className='lg:col-span-1'>
                    <Sidebar>
                        <SidebarLink
                            to='/admin'
                            text='Компании'
                            setCurrentLink={() => setCurrentLink("/admin")}
                            currentLink={currentLink}
                            icon={<PiHouseSimpleFill className='shrink-0 text-slate-200' size={27} />}
                        />
                        <SidebarLink
                            to='/admin/power-bi'
                            text='Power BI'
                            setCurrentLink={() => setCurrentLink("/admin/power-bi")}
                            currentLink={currentLink}
                            icon={<MdDashboard className='shrink-0 text-slate-200' size={27} />}
                        />
                        <SidebarLink
                            to='/admin/employees'
                            text='Сотрудники'
                            setCurrentLink={() => setCurrentLink("/admin/employees")}
                            currentLink={currentLink}
                            icon={<FiUsers className='shrink-0 text-slate-200' size={27} />}
                        />
                        <SidebarLink
                            to='/admin/sheets'
                            text='Sheets'
                            setCurrentLink={() => setCurrentLink("/admin/sheets")}
                            currentLink={currentLink}
                            icon={<BiSolidSpreadsheet className='shrink-0 text-slate-200' size={27} />}
                        />
                    </Sidebar>
                </div>
                <div className='lg:col-span-4'>
                    <Outlet/>
                </div>
            </div>
        </div>
    )
}

export default AdminLayout