import { FC } from 'react'

interface StatementItemProps {
    date: string
    text: string
    status: string
    type?: "Sales Report" | "Касса"
}

interface Colors {
    [key: string]: string | undefined
}

const colors: Colors = {
    pending: "text-gray-200",
    success: "text-green-600",
    error: "text-red-600"
}

const HistoryItem: FC<StatementItemProps> = ({date, text, status, type}) => {
    return (
        <div className='bg-gray-800 px-6 py-4 rounded-xl shadow'>
            <div className='flex items-center justify-between w-full space-x-4'>
                <div className='flex items-center space-x-8'>
                    <p className='text-white text-lg'>{date}</p>
                    {type && <p className='text-white text-lg'>{type}</p>}
                </div>
                <p className={`text-lg ${colors[status]}`} >{text}</p>
            </div>
        </div>
    )
}

export default HistoryItem