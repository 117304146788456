import { useState, useCallback, useEffect, FC } from 'react'
import PowerBI from '../../components/PowerBI'
import { IoExpandOutline } from "react-icons/io5";
import { AiOutlineShrink } from "react-icons/ai";
import useAuth from '../../hooks/useAuth';
import { Navigate } from 'react-router-dom';

const Dashboard: FC = () => {
	const {isManager} = useAuth()

	if (!isManager) {
		return <Navigate to={"/statement"} replace />
	}
	
	return (
		<div className={`md:px-14 h-full mt-4 p-3`}>
			<div className='flex flex-col h-full'>
				<div className='flex flex-col space-y-6 h-full'>
					<div className='flex justify-between'>
						<span className='text-gray-300 font-bold text-xl'>Power BI</span>
					</div>
					<PowerBI />
				</div>
			</div>
		</div>
	)
}

export default Dashboard