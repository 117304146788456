import React, { FC } from 'react';
import { Table } from 'antd';
import { GroupedOrders } from './types';

interface MainTableProps {
    groupedOrders: GroupedOrders[];
}

const MainTable: FC<MainTableProps> = ({ groupedOrders }) => {
    const columns = [
        {
            title: 'Адрес',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Всего продаж',
            dataIndex: 'totalPrice',
            key: 'totalPrice',
            render: (value: number) => value.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        },
        {
            title: 'Комиссия',
            dataIndex: 'totalCommission',
            key: 'totalCommission',
            render: (value: number) => value.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        },
        {
            title: 'Стоимость доставки',
            dataIndex: 'totalDeliveryPrice',
            key: 'totalDeliveryPrice',
            render: (value: number) => value.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        },
        {
            title: 'Всего заказов',
            dataIndex: 'totalOrders',
            key: 'totalOrders',
        },
    ];

    return <Table columns={columns} dataSource={groupedOrders} rowKey="address" pagination={false} />;
};

export default MainTable;