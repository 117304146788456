import React, { FC } from 'react'
import { YandexTableProps } from '../../../models/chicken-food/YandexOrder'
import { Title } from '../../UI/Title'
import Spinner from '../../UI/Spinner'
import MainTable from './MainTable'
import CancelledOrdersTable from './CancelledTable'
import RetentionOrdersTable from './RetentionsTable'
import { Spin } from 'antd'


const YandexInfo: FC<YandexTableProps> = ({groupedOrders, cancelledOrders, retentionOrders}) => {
    return (
        <div className='flex flex-col space-y-10'>
            <div className="flex flex-col space-y-8 bg-slate-900 p-6 lg:p-8 rounded-[20px] shadow h-full">
                <Title className="text-white" medium>
                    Общая информация
                </Title>
                {
                    groupedOrders ? (
                        <MainTable groupedOrders={groupedOrders} />
                    ): (
                        <div className="flex items-center justify-center h-full">
                            <Spin size="large" />
                        </div>
                    )
                }
            </div>
            <div className="flex flex-col space-y-8 bg-slate-900 p-6 lg:p-8 rounded-[20px] shadow h-full">
                <Title className="text-white" medium>
                    Отмененные заказы
                </Title>
                {
                    cancelledOrders ? (
                        <CancelledOrdersTable cancelledOrders={cancelledOrders} />
                    ) : (
                        <div className="flex items-center justify-center h-full">
                            <Spin size="large" />
                        </div>
                    )
                }
            </div>

            <div className="flex flex-col space-y-8 bg-slate-900 p-6 lg:p-8 rounded-[20px] shadow h-full">
                <Title className="text-white" medium>
                    Удержания
                </Title>
                {
                    retentionOrders ? (
                        <RetentionOrdersTable retentionOrders={retentionOrders} />
                    ) : (
                        <div className="flex items-center justify-center h-full">
                            <Spin size="large" />
                        </div>
                    )
                }
            </div>

            <style>
            {`
                .ant-table {
          background-color: transparent !important;
        }

        .ant-table-thead > tr > th {
          background-color: #1F2937 !important; /* Dark background for the header */
          color: #e8e8e8 !important;
          font-size: 18px !important;
        }

        .ant-table-tbody > tr > td {
          background-color: transparent !important; /* Transparent body rows */
          color: #e8e8e8 !important;
          font-size: 16px !important;
        }

        .ant-table-tbody > tr:hover > td {
          background-color: rgba(255, 255, 255, 0.1) !important; /* Slight hover effect */
        }

        .ant-pagination-item {
          background-color: transparent !important;
          border-color: #3a3a3a !important;
        }

        .ant-pagination-item a {
          color: #e8e8e8 !important;
        }

        .ant-pagination-item-active {
          border-color: #595959 !important;
        }

        .ant-pagination-item-active a {
          color: #ffffff !important;
        }

        .ant-pagination-options {
          background-color: transparent !important;
        }

        .ant-table-container table > thead > tr:first-child th:first-child {
          border-top-left-radius: 10px !important;
        }

        .ant-table-container table > thead > tr:first-child th:last-child {
          border-top-right-radius: 10px !important;
        }
            `}
            </style>
        </div>
    )
}

export default YandexInfo