import React, { FC } from 'react';
import { Table } from 'antd';
import { CancelReasons } from './types';
import { CancelledOrders } from '../glovo/types';
import { ColumnType } from 'antd/es/table';

interface CancelledOrdersTableProps {
    cancelReasons: CancelReasons[];
}

const CancelledOrdersTable: FC<CancelledOrdersTableProps> = ({ cancelReasons }) => {
    const addressFilters = Array.from(new Set(cancelReasons.map(order => order.address))).map(address => ({
        text: address, // Display text for the filter
        value: address, // Value to filter by
    }));

    const cancelReasonFilters = Array.from(new Set(cancelReasons.map(order => order.cancelReason))).map(reason => ({
        text: reason,
        value: reason,
    }));

    const columns: ColumnType<CancelReasons>[] = [
        {
            title: 'Адрес',
            dataIndex: 'address',
            key: 'address',
            filters: addressFilters,
            onFilter: (value: any, record: CancelReasons) => record.address === value,
        },
        {
            title: 'Причина отмены',
            dataIndex: 'cancelReason',
            key: 'cancelReason',
            filters: cancelReasonFilters,
            onFilter: (value: any, record: CancelReasons) => record.cancelReason === value,
        },
        {
            title: 'Количество отмен',
            dataIndex: 'cancelCount',
            key: 'cancelCount',
        },
    ];

    return <Table columns={columns} dataSource={cancelReasons} rowKey="id" pagination={false} />;
};

export default CancelledOrdersTable;