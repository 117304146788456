import { FC, useState } from 'react'
import {useNavigate} from 'react-router-dom'
import { useLoginMutation } from '../../../api/baseApi'
import { userCreated } from '../../../store/reducers/userSlice'
import useToast from '../../../hooks/useToast'
import { LoginDto } from '../../../models/dto/LoginDto'
import { useAppDispatch } from '../../../hooks/redux'
import { Employee } from '../../../models/Employee'
import { Form } from '../../UI/Form'
import { SubmitHandler, useForm } from 'react-hook-form'
import Button from '../../UI/Button'


const LoginForm: FC = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [login, {isLoading}] = useLoginMutation()
    const { showToast, ToastComponent } = useToast();

    const { register, handleSubmit, setError, formState: { errors }, watch, reset } = useForm<LoginDto>();
    const formState = watch();

    const [isShaking, setShaking] = useState(false);

    const shake = () => {
        setShaking(true);

        setTimeout(() => {
            setShaking(false);
        }, 1000);
    };


    const onSubmit: SubmitHandler<LoginDto> = async () => {
        try {
            const response = await login(formState).unwrap()
            const employee: Employee = response?.employee

            // if (!employee.roles?.find(role => role.value === "MANAGER")) {
            //     shake()
            //     showToast("У вас нет доступа на платформу. Обратитесь к вашему менеджеру", {warning: true})
            //     return
            // }

            localStorage.setItem("access_token", response?.accessToken)
            dispatch(userCreated(response?.employee))
            navigate("/dashboard") 
        } catch(e: any) {
            shake()
            const error = e.data?.message || 'Не предвиденная ошибка, попробуйте позже'
            showToast(error, { error: true })
        }
    }


    return (
        <Form className='flex flex-col max-w-md space-y-8' onSubmit={handleSubmit(onSubmit)}>
            <Form.Heading medium>
                Вход
            </Form.Heading>
            <Form.TextField
                {...register("login", {required: 'Логин не должен быть пустым'})}
                error={errors.login}
                label='Логин'
                theme='light'
                placeholder="Введите логин"
            />
            <Form.TextField
                {...register("password", {required: 'Пароль не должен быть пустым'})}
                error={errors.password}
                label='Пароль'
                theme='light'
                toggle={true}
                placeholder="Введите пароль"
            />
            <Button
                type='submit'
                text='Войти'
                theme='dark'
                isShaking={isShaking}
                isLoading={isLoading}
            />
            {ToastComponent()}
        </Form>
    )
}

export default LoginForm