import React, { FC, useEffect, useState } from 'react';
import { Table } from 'antd';
import { Statistics } from './types';

interface MainTableProps {
    statistics: Statistics[];
}

const numberFormatter = new Intl.NumberFormat('ru-RU', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
});

const MainTable: FC<MainTableProps> = ({ statistics }) => {
    const [dataWithSummary, setDataWithSummary] = useState<Statistics[]>([]);

    // Function to calculate totals
    const calculateTotals = (data: Statistics[]) => {
        const totals: Statistics = {
            address: 'Итоги', // "Totals" in Russian
            totalSales: 0,
            totalSuccessfulOrders: 0,
            totalUnsuccessfulOrders: 0,
            totalCommission: 0,
        };

        data.forEach((record) => {
            totals.totalSales += Number(record.totalSales) || 0;
            totals.totalSuccessfulOrders += Number(record.totalSuccessfulOrders) || 0;
            totals.totalUnsuccessfulOrders += Number(record.totalUnsuccessfulOrders) || 0;
            totals.totalCommission += Number(record.totalCommission) || 0;
        });

        return totals;
    };

    // Add totals row to the statistics data
    useEffect(() => {
        const totals = calculateTotals(statistics);
        const dataWithTotals = [...statistics, totals];
        setDataWithSummary(dataWithTotals);
    }, [statistics]);

    const columns = [
        {
            title: 'Адрес',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Успешные заказы',
            dataIndex: 'totalSuccessfulOrders',
            key: 'totalSuccessfulOrders',
        },
        {
            title: 'Неуспешные заказы',
            dataIndex: 'totalUnsuccessfulOrders',
            key: 'totalUnsuccessfulOrders',
        },
        {
            title: 'Всего продаж',
            dataIndex: 'totalSales',
            key: 'totalSales',
            render: (value: number) => numberFormatter.format(value),
        },
        {
            title: 'Комиссия',
            dataIndex: 'totalCommission',
            key: 'totalCommission',
            render: (value: number) => numberFormatter.format(value),
        },
    ];

    return <Table columns={columns} dataSource={dataWithSummary} rowKey="address" pagination={false} />;
};

export default MainTable;