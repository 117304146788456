import React, { ReactNode, useState } from 'react';
import Select, { MultiValue } from 'react-select';


export interface OptionType {
  label: string;
  value: string;
}

export interface MultiSelectProps {
  label: string
  theme: "light" | "dark"
  error?: string | undefined
  subtitle?: ReactNode | string
  options: OptionType[] | undefined;
  selectedOptions: OptionType[];
  onChange: (selectedOptions: OptionType[]) => void;
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  options,
  selectedOptions,
  onChange,
  label,
  theme,
  error,
  subtitle
}) => {
    const handleChange = (selectedOptions: MultiValue<OptionType>) => {
        onChange(selectedOptions as OptionType[]);
    };

    return (
		<div className="flex flex-col">
			<span className={`${theme === "light" ? "text-gray-700" : "text-gray-300"} text-lg lg:text-xl mb-2`}>{label}</span>
			<Select
				isMulti
				placeholder="Выберите роли"
				value={selectedOptions}
				onChange={handleChange}
				options={options}
			/>
			{(!error && subtitle) && <span className='text-gray-400 mt-2'>{subtitle}</span>}
			{error && <span className='text-red-400 mt-2'>{error}</span>}
		</div>
	);
};

export default MultiSelect
