import {BaseQueryApi, BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError} from '@reduxjs/toolkit/query/react'
import { API_URL } from '../utils/consts'
import { userClosed } from '../store/reducers/userSlice'
import { LoginResponse } from '../models/responses/LoginResponse'
import { LoginDto } from '../models/dto/LoginDto'

const baseQuery: any = fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers, { getState }) => {
        const token = localStorage.getItem("access_token")

        headers.set("x-client-origin", "web")
        if (token) {
            headers.set("Authorization", `Bearer ${token}`)
        }

        return headers
    },
    credentials: "include"
})

const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError> 
    = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
        const refreshResult = await baseQuery({url: "/auth/refresh", method: 'POST'}, api, extraOptions); 

        if (refreshResult.data) {
            localStorage.setItem("access_token", refreshResult.data.accessToken)
            result = await baseQuery(args, api, extraOptions);
        } else {
            api.dispatch(baseApi.util.resetApiState())
            api.dispatch(userClosed())
            localStorage.removeItem("access_token")
        }
    }

    return result
}

export const baseApi = createApi({
    reducerPath: 'baseApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ["Report", "Company", "Credentials", "Statement", "Employee", "Sheets", "Files", "Shashlychok"],
    endpoints: (builder) => ({
        login: builder.mutation<LoginResponse, LoginDto>({
            query: (credentials) => ({
                url: '/auth/login',
                method: 'POST',
                body: credentials
            })
        })
    }),
})

export const { useLoginMutation } = baseApi
