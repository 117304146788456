import { FC, useState } from 'react'
import { Form } from '../../UI/Form'
import { OptionType } from '../../UI/SelectInput'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useAddFilesMutation, useParseFilesMutation } from '../../../api/filesApi'
import { useGetSheetsQuery } from '../../../api/sheetsApi'
import useToast from '../../../hooks/useToast'
import { FilesDto } from '../../../models/dto/FilesDto'
import FileUpload from '../../UI/FileUpload'
import { useGetCashMutation } from '../../../api/accountApi'
import Spinner from '../../UI/Spinner'


const SalesReportForm: FC = () => {
    const [googleSheetsUrl, setUrl] = useState()
    const [account, setAccount] = useState()

    const [add, {isLoading}] = useAddFilesMutation()
    const [parse] = useParseFilesMutation()
    const {data: sheets} = useGetSheetsQuery("", {
        refetchOnMountOrArgChange: true
    })

    const [error, setError] = useState<string>()
    const [isShaking, setShaking] = useState<boolean>(false);
    const { showToast, ToastComponent } = useToast();

    const { register, handleSubmit, setValue, formState: { errors }, watch, reset } = useForm<FilesDto>();
    const [wallets, setWallets] = useState<string[]>()
    const [getWallets, {isLoading: isFetching}] = useGetCashMutation()

    const shake = () => {
        setShaking(true);

        setTimeout(() => {
            setShaking(false);
        }, 1000);
    };

    const handleSelectChange = async (option: OptionType) => {
        setValue("googleSheetsUrl", option.value)
        try {
            const wallets = await getWallets({googleSheetsUrl: option.value}).unwrap()
            setWallets(wallets)
        } catch(e) {
            console.log(e)
        }
    }

    const handleCashChange = (option: OptionType) => {
        setValue("account", option.value)
    }

    const submit: SubmitHandler<FilesDto> = async (state) => {
        const formData = new FormData();
        if (!state.files) {
            setError("Файлы пустые")
            return
        }

        state.files.forEach((file) => {
            formData.append('files', file);
        });
        formData.append('googleSheetsUrl', state.googleSheetsUrl)
        formData.append('account', state.account)

        try {
            const currentDate = new Date()
            const file = await add({
                date: currentDate.toISOString(),
                status: "pending",
                text: "В обработке",
                type: "Sales Report",
            }).unwrap()
            
            showToast("Sales Report отправлен в обработку", {success: true})

            formData.append("fileId", String(file.id))
            formData.append("type", "parseSalesReport")
            await parse(formData)
        }  catch(e: any) {
            shake()
            showToast(e?.data?.message, {error: true})
        }
    }
    
    return (
        <Form className='max-w-2xl space-y-8' onSubmit={handleSubmit(submit)}>
            <Form.Heading className='text-white' medium>Загрузить Sales Report</Form.Heading>
            <Form.Select
                {...register("googleSheetsUrl", {required: "Google Sheet не был выбран"})}
                label='Название Google Sheet'
                theme='dark'
                options={sheets?.map(sheet => ({label: sheet.name, value: sheet.googleSheetsUrl})) || []}
                selectedOption={googleSheetsUrl}
                error={errors.googleSheetsUrl?.message} 
                onChange={handleSelectChange} 
            />
            {
                isFetching && <Spinner/>
            }
            {
                (!isFetching && wallets) &&
                <Form.Select
                    {...register("account", {required: false})}
                    label="Выберите кошелек"
                    theme='dark'
                    options={wallets?.map((item: string) => ({label: item, value: item})) || []}
                    selectedOption={account}
                    error={errors.account?.message} 
                    onChange={handleCashChange} 
                />
            }
            <FileUpload
                id='sales'
                accept='.xsl, .xlsx'
                setValue={(selectedFiles) => {
                    setValue("files", selectedFiles, {shouldValidate: true})
                    setError("")
                }}
                error={error}
            />
            <Form.FormButton
                type='submit'
                text='Отправить'
                theme='light'
                isShaking={isShaking}
                isLoading={isLoading}
            />
            {ToastComponent()}
        </Form>
    )
}

export default SalesReportForm