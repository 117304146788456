import React, { FormHTMLAttributes, PropsWithChildren } from 'react'
import { Title, TitleProps } from '../Title';
import Input, { InputProps } from '../Input';
import MultiSelect, { MultiSelectProps } from '../MultiSelect';
import Button, { ButtonProps } from '../Button';
import SelectInput, { SelectInputProps } from '../SelectInput';

function Form({ children, ...props }: PropsWithChildren<FormHTMLAttributes<HTMLFormElement>>) {
    return (
        <form
            className="flex flex-col space-y-8"
            {...props}>
            {children}
        </form>
    );
}

namespace Form {

    export const Heading = ({ children, ...props }: PropsWithChildren<TitleProps>) => {
        return <Title {...props}>{children}</Title>;
    }; 

    export const TextField = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
        return <Input {...props} ref={ref} />;
    });

    export const MultiSelectInput = React.forwardRef<HTMLInputElement, MultiSelectProps>((props, _) => {
        return <MultiSelect {...props} />;
    });

    export const Select = React.forwardRef<HTMLInputElement, SelectInputProps>((props, _) => {
        return <SelectInput {...props} />;
    });

    export const FormButton = ({ ...props }: ButtonProps) => {
        return (
            <div className='flex justify-center lg:justify-end'>
                <Button {...props}/>
            </div> 
        );
    };

}

export { Form }