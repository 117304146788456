import React, { FC, useState } from 'react'
import { Form } from '../../UI/Form'
import { useGetSessionByDateMutation, useGetUsersQuery } from '../../../api/shashlychokApi'
import { User } from '../../../models/shashlychok/User'
import { OptionType } from '../../UI/MultiSelect'
import { SubmitHandler, useForm } from 'react-hook-form'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale/ru';
import { SessionDto } from '../../../models/shashlychok/SessionDto'
import { Session } from '../../../models/shashlychok/Session'
import useToast from '../../../hooks/useToast'
import SessionInfo from '../SessionInfo'
import { Title } from '../../UI/Title'
import { locations } from '../../../utils/consts'


const SessionForm: FC = () => {
    registerLocale('ru', ru)

    const {data: users} = useGetUsersQuery("", {
        refetchOnMountOrArgChange: true
    })
    const [getSession, {isLoading}] = useGetSessionByDateMutation()
    const {register, handleSubmit, formState: {errors}, setValue} = useForm<SessionDto>()

    const [user, setUser] = useState<OptionType>()
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [session, setSession] = useState<{session: Session, prevSession: Session}>()

    const [isShaking, setShaking] = useState<boolean>(false);
    const { showToast, ToastComponent } = useToast();

    const shake = () => {
        setShaking(true);

        setTimeout(() => {
            setShaking(false);
        }, 1000);
    };

    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date)
        setValue("date", formatDate(date))
    };

    const formatDate = (date: Date | null): string => {
        return date ? format(date, 'dd.MM.yyyy') : '';
    };

    const handleUserChange = (option: OptionType) => {
        setUser(option)
        setValue("userId", Number(option.value))
    }

    const submit: SubmitHandler<SessionDto> = async (data) => {
        try {  
            const request = await getSession(data).unwrap()
            setSession(request)
        } catch(e: any) {
            showToast(e?.data?.message || "Произошла ошибка", {error: true})
            shake()
        }
    }

    return (
        <div className='flex flex-col space-y-10'>
            <Title className='text-slate-300' large>Отчет по Смене</Title>
            <Form className='flex flex-col md:flex-row md:items-center md:justify-between space-y-6 md:space-y-0 max-w-3xl' onSubmit={handleSubmit(submit)}> 
                <Form.Select
                    {...register("userId", {required: "Филиал не выбран"})}
                    label='Выберите Филал (Аккаунт)'
                    theme='dark'
                    options={users?.slice(1).map((user: User, id) => ({ label: locations[id], value: String(user.id) })) || []}
                    selectedOption={user}
                    error={errors.userId?.message} 
                    onChange={handleUserChange} 
                />
                <div className='flex flex-col space-y-2'>
                    <label className='text-gray-300 text-lg lg:text-xl'>Выберите дату</label>
                    <DatePicker
                        locale={"ru"}
                        selected={selectedDate}
                        onChange={handleDateChange}
                        dateFormat="dd.MM.yyyy"
                        placeholderText="Выберите дату"
                        className='bg-white w-[100%] px-3 py-2 rounded'
                    />
                </div>
                <Form.FormButton
                    type='submit'
                    text='Применить'
                    theme='light'
                    isShaking={isShaking}
                    isLoading={isLoading}
                />
                {ToastComponent()}
            </Form>
            <SessionInfo
                session={session}
            />
        </div>
    )
}

export default SessionForm